import React from 'react';
import DynamicForm from '../Common/DynamicForm';
import withEditMode from '../Common/withEditMode';
import { TenantModel } from '../../models/Tenant/Tenant';

interface TenantFormProps {
  item?: TenantModel | null;
  onSave: (role: TenantModel) => void;
}

const TenantForm: React.FC<TenantFormProps> = ({ item, onSave }) => {

  const initialValues: TenantModel = item || {_id: '', name: '', admin: '' };
  const fields = [
    { name: 'name' as keyof TenantModel, label: 'Tenant Name' },
    { name: 'admin' as keyof TenantModel, label: 'Tenant Admin'},
  ];

  return <DynamicForm initialValues={initialValues} fields={fields} onSave={onSave} />;
};

export default withEditMode(TenantForm);
