import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogTitle, IconButton, DialogContent, Card, CardContent, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import CloseIcon from '@mui/icons-material/Close';

import TaskNameField from './TaskNameField';
import TaskStatusField from './TaskStatusField';
import TaskCombinedField from './TaskCombinedField';
import TaskAnswerField from './TaskAnswerField';
import TaskUserDetail from './TaskUserDetail';
import TaskCommentAndHistory from './TaskCommentAndHistory';
import TaskPriorityField from './TaskPriorityField';
import TaskFileUploadField from './TaskFileUploadField';
import TaskFileTable from './TaskFileTable';
import { Subtask, Task } from '../../../models/Task/Pano';
import { TaskManagementService } from '../../../services/api';

// Define the interface for props

interface SubtaskDetailProps {
    open: boolean;
    onClose: () => void;
    subTask?: Subtask;
    parent?: Task;
    subtaskIndex: number;
    onChangeSubTask: (newSubtaskList: Subtask[]) => void;
}

const SubtaskDetail: React.FC<SubtaskDetailProps> = ({ open, onClose, subTask, parent, subtaskIndex, onChangeSubTask }) => {

    const [subtask, setSubtask] = useState<Subtask>();
    const [parentTask, setParentTask] = useState<Task>();
    const [subIndex, setSubIndex] = useState<number>();

    useEffect(() => {
        setSubtask(subTask);
        setParentTask(parent);
        setSubIndex(subtaskIndex);
    }, [subTask, parent, subtaskIndex]);


    const subTaskUpdate = (field: string, value: any) => {
        if (subtask) {
            const updatedSubTask = { ...subtask, [field]: value };
            setSubtask(updatedSubTask);

            const allSubtask = parentTask?.subtasks;
            if (typeof subIndex === "number" && allSubtask) {
                allSubtask[subIndex] = updatedSubTask;
                setParentTask({ ...parentTask, subtasks: allSubtask });
                onChangeSubTask(allSubtask);
                if (["status", "responsible", "targetDate", "priority", "comments", "documents"].includes(field)) {
                    blurUpdate(true);
                }
            }
        }
    };


    const blurUpdate = async (blurValue: boolean) => {

        if (blurValue && parentTask) {
            const response = await TaskManagementService.update(parentTask.taskID, parentTask, "task");
            console.log("my subtask detail response -> ", response);

        } else {
            console.log("blur value -> ", blurValue);
        }
    };

    const fileUpload = async (file: File) => {
        const parent = { ...parentTask }
        if (parent._id) {
            const formData = new FormData();
            formData.append("files", file);
            const fileUrl = "file/" + parent.taskID + "/" + subtask?.taskID;
            const response = await TaskManagementService.create(formData, fileUrl);
            const allsubtask: Subtask[] = response.subtasks;
            const newSubtask = allsubtask.find((sub: Subtask) => sub.taskID === subtask?.taskID);
            setSubtask(newSubtask);
            console.log("my parent task file up response -> ", response);
        }
    }

    return (

        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
            PaperProps={{ style: { height: "100vh", width: "100vw", margin: 10 } }}
        >
            <DialogActions style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <DialogTitle style={{ margin: 0 }}>
                    <div>
                        Subtask-ID: <span>{subtask?.taskID}</span>
                    </div>                </DialogTitle>
                <IconButton onClick={() => { onClose() }}>
                    <CloseIcon />
                </IconButton>
            </DialogActions>
            <DialogContent
                style={{
                    maxHeight: "70vh",
                    overflowY: "auto",
                    scrollbarWidth: "thin",
                    scrollbarColor: "#6b6b6b #f1f1f1",
                }}
            >

                <Grid container columns={12}>

                    <Grid container rowSpacing={4} size={7} >

                        <Grid size={10}>

                            <Card square>
                                <CardContent>
                                    <Typography variant='h5' color='red'>
                                        Regulation Ref:
                                    </Typography>

                                    <TaskCombinedField
                                        item={parentTask?.combinedField || ""}
                                        multiline={true}
                                        rows={3}
                                        label={false}
                                        disabled={true}
                                        onBlurCallBack={() => { }}
                                        onTaskCombinedFieldChange={() => { }}
                                    />

                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid size={10}>

                            <Card square>
                                <CardContent>
                                    <TaskNameField
                                        item={subtask?.name || ""}
                                        multiline={true}
                                        rows={3}
                                        onTaskNameChange={(newValue) => { subTaskUpdate("name", newValue) }}
                                        onBlurCallBack={(isBlurred) => { blurUpdate(isBlurred) }}
                                        permission={false}
                                    />
                                </CardContent>
                            </Card>

                        </Grid>

                        <Grid size={10}>

                            <Card>
                                <CardContent>
                                    <TaskAnswerField
                                        item={subtask?.answer || ""}
                                        multiline={true}
                                        rows={3}
                                        onTaskAnswerChange={(newValue) => { subTaskUpdate("answer", newValue) }}
                                        onBlurCallBack={(isBlurred) => { blurUpdate(isBlurred) }}
                                        permission={false}
                                    />

                                </CardContent>
                            </Card>

                        </Grid>

                    </Grid>

                    <Grid container size={5} justifyContent="center">

                        <Grid size={8} sx={{ marginBottom: "30px" }}>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid size={6}>
                                            <TaskStatusField
                                                item={subtask?.status || ""}
                                                onStatusChange={(newStatus) => { subTaskUpdate("status", newStatus) }}
                                                allStatus={["Not Assigned", "To Do", "In Progress", "Done", "Cancelled"]}
                                                permission={true}
                                            />
                                        </Grid>
                                        <Grid size={6}>
                                            <TaskPriorityField
                                                item={subtask?.priority || ""}
                                                onPriorityChange={(newPriority) => { subTaskUpdate("priority", newPriority) }}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid size={10} sx={{ marginTop: "10px" }}>
                            <Card square sx={{ backgroundColor: "whitesmoke" }}>
                                <CardContent>
                                    <TaskUserDetail
                                        selectedTicketOwner={subtask?.responsible || ""}
                                        selectedTicketOwnerManager=''
                                        selectedInternalAuditor=''
                                        selectedIndependentAuditor=''
                                        targetDate={subtask?.targetDate || null}
                                        onTicketOwnerChange={(newTicketOwner) => { subTaskUpdate("responsible", newTicketOwner) }}
                                        onTicketOwnerManagerChange={(newTicketOwnerManager) => { console.log("newTicketOwnerManager -> ", newTicketOwnerManager) }}
                                        onInternalAuditorChange={(newInternalAuditor) => { console.log("newInternalAuditor -> ", newInternalAuditor) }}
                                        onIndependentAuditorChange={(newIndependentAuditor) => { console.log("newIndependentAuditor -> ", newIndependentAuditor) }}
                                        onTargetDateChange={(newTargetDate) => { subTaskUpdate("targetDate", newTargetDate) }}
                                        disabled={true}
                                        requestDate={null} // modele ekle
                                        creationDate={null} // modele ekle
                                        lastUpdate={subtask?.lastUpdate || null}
                                    />


                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>

                    <Grid container size={12} sx={{ marginTop: "30px" }}>
                        <Card square>
                            <CardContent>
                                <TaskFileUploadField
                                    type='detail'
                                    allDocuments={subtask?.documents || []}
                                    onFileChange={(newFile) => { fileUpload(newFile) }}
                                    permission={false}
                                />
                                <TaskFileTable
                                    documents={subtask?.documents || []}
                                    onFileChange={(newFiles) => { subTaskUpdate("documents", newFiles) }}
                                />
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid container size={12} sx={{ marginTop: "40px" }}>
                        <Card square>
                            <CardContent>
                                <Grid size={10}>
                                    <TaskCommentAndHistory taskComment={subtask?.comments || []} onCommentAndHistoryChange={(newComments) => { subTaskUpdate("comments", newComments) }} />
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

            </DialogContent>

        </Dialog>

    );
};

export default SubtaskDetail;