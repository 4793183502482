import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Card, CardContent, Avatar, TextField, Tooltip, IconButton, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';

import { Comment } from '../../../models/Task/Pano';
import { useAuth } from '../../../context/AuthContext';

interface TaskCommentAndHistoryProps {
    taskComment: Comment[];
    onCommentAndHistoryChange: (newComments: Comment[]) => void;
}

/*
    TODO
    -history eklenecek
    -anlık session daki user ı comment owner a ata

*/


const TaskCommentAndHistory: React.FC<TaskCommentAndHistoryProps> = ({ taskComment, onCommentAndHistoryChange }) => {

    const [mainTab, setMainTab] = useState("comment");
    const [commentTab, setCommentTab] = useState("internal");
    const [historyTab, setHistoryTab] = useState("internal");
    const { user } = useAuth();



    const [comments, setComments] = useState<Comment[]>();

    useEffect(() => {
        setComments(taskComment || []);
    }, [taskComment]);


    const [filteredComment, setFilteredComments] = useState<Comment[]>([]);
    const [addComment, setAddComment] = useState(false);

    const [newComment, setNewComment] = useState("");


    useEffect(() => {
        setFilteredComments(comments ? comments.filter((comm) => comm.type === commentTab) : []);
    }, [comments, commentTab]);


    const handleMainTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setMainTab(newValue);
    };

    const handleCommentTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setCommentTab(newValue);
    };

    const handleHistoryTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setHistoryTab(newValue);
    };

    const handleNewCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setNewComment(value);
    };

    const saveComment = () => {
        const updatedComments = [
            ...(comments || []),
            {
                comment: newComment,
                owner: user?.firstname + " "+ user?.lastname,
                type: commentTab,
            },
        ];

        setComments(updatedComments);
        onCommentAndHistoryChange(updatedComments);
        setNewComment("");
    };

    const deleteComment = (index: number) => {

        if (comments) {
            const newCommand = comments.filter((comment) => comment.type !== commentTab);

            filteredComment.splice(index, 1);

            const allCom = [...newCommand, ...filteredComment];

            setComments(allCom);
            onCommentAndHistoryChange(allCom);
        }else{
            console.log("found any comments");
        }
    };

    return (

        <Box sx={{ minWidth: "50vw" }}>

            <Grid container size={12} sx={{ marginBottom: "10px" }}>
                <Tabs
                    value={mainTab}
                    onChange={handleMainTabChange}
                    textColor="primary"
                    indicatorColor="primary"
                    aria-label="main tabs"
                >
                    <Tab
                        value="comment"
                        label="Comments"
                        sx={{
                            fontSize: "20px",
                            "&.Mui-selected": {
                                color: "#0b2343",
                            },
                        }}
                    />
                    <Tab
                        value="history"
                        label="History"
                        sx={{
                            fontSize: "20px",
                            "&.Mui-selected": {
                                color: "#0b2343",
                            },
                        }}
                    />
                </Tabs>

            </Grid>


            <Grid container size={12} sx={{ marginBottom: "10px" }}>

                {mainTab === "comment" ? (

                    <Grid container>
                        <Grid size={10}>
                            <Tabs
                                value={commentTab}
                                onChange={handleCommentTabChange}
                                textColor="primary"
                                indicatorColor="primary"
                                aria-label="main tabs"

                            >
                                <Tab value="internal" label="Internal" sx={{ fontSize: "12px" }} />
                                <Tab value="external" label="External" sx={{ fontSize: "12px" }} />
                            </Tabs>
                        </Grid>

                        <Grid size={10}>
                            <div>
                                {filteredComment && filteredComment.length > 0 ? (
                                    filteredComment.map((comm, index) => (

                                        <Card square style={{ margin: "15px", minWidth: "40vw" }} key={index}>
                                            <CardContent>
                                                <Box display="flex" alignItems="center">

                                                    <Tooltip title={comm.owner}>
                                                        <Avatar>
                                                            {comm.owner[0].toUpperCase()}
                                                        </Avatar>
                                                    </Tooltip>
                                                    <TextField
                                                        value={comm.comment}
                                                        style={{ marginLeft: "10px", minWidth: "30vw" }}
                                                    />

                                                    <IconButton onClick={() => { deleteComment(index) }}>
                                                        <DeleteIcon style={{ color: "red" }} />
                                                    </IconButton>
                                                </Box>
                                            </CardContent>
                                        </Card>

                                    ))
                                ) : (
                                    <p>No comments found.</p>
                                )}
                            </div>
                        </Grid>
                    </Grid>



                ) : mainTab === "history" ? (
                    <Grid size={10}>
                        <Tabs
                            value={historyTab}
                            onChange={handleHistoryTabChange}
                            textColor="primary"
                            indicatorColor="primary"
                            aria-label="main tabs"

                        >
                            <Tab value="internal" label="Internal" sx={{ fontSize: "12px" }} />
                            <Tab value="external" label="External" sx={{ fontSize: "12px" }} />
                        </Tabs>
                    </Grid>
                ) : null}
            </Grid>

            <Grid container size={12}>
                {addComment ? (

                    <Grid size={10} display="flex">
                        <Card>
                            <CardContent style={{ display: 'flex', alignItems: 'flex-end' }}>
                                <TextField
                                    value={newComment}
                                    onChange={handleNewCommentChange}
                                    style={{ minWidth: "30vw", marginRight: "8px" }} // Sağ tarafta biraz boşluk bırakmak için marginRight ekledik
                                />

                                <IconButton onClick={() => {
                                    saveComment();
                                    setAddComment(!addComment);
                                    setNewComment("");
                                }}>
                                    <SendIcon />
                                </IconButton>
                            </CardContent>
                        </Card>
                    </Grid>



                ) : null}

                <Grid container size={12} justifyContent="flex-end" alignItems="flex-end" sx={{ minWidth: "30vw" }}>
                    {mainTab === "comment" ? (
                        <Grid>
                            <Button
                                variant="contained"
                                size="small"
                                style={{ backgroundColor: "#0b2343", marginLeft: "10px" }}
                                onClick={() => {
                                    setAddComment(!addComment);
                                    setNewComment("");
                                }}
                            >
                                Add Comment
                            </Button>
                        </Grid>

                    ) : null
                    }
                </Grid>


            </Grid>
        </Box>
    );
};

export default TaskCommentAndHistory;