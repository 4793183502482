import * as XLSX from 'xlsx';

class FileService {
  public handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, callback: (json: any[]) => void): void => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target!.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });

        const worksheet = workbook.Sheets[workbook.SheetNames[0]];

        const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0] as string[];

        const json: any[] = XLSX.utils.sheet_to_json(worksheet, { defval: '' });

        const completedJson = json.map(row => {
          const completedRow: { [key: string]: string } = {};

          headers.forEach(header => {
            completedRow[header] = row[header] !== undefined ? row[header] : '';
          });

          return completedRow;
        });

        callback(completedJson);
      };

      reader.readAsArrayBuffer(file);
    }
  };

  public jsonToXlsx = (jsonData: any, fileName: string) => {
    const worksheet = XLSX.utils.json_to_sheet(jsonData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    XLSX.writeFile(workbook, fileName+'.xlsx');
  }
}

export default FileService;
