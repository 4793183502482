import React, { useState, useEffect } from 'react';
import { Menu, MenuItem, Typography, Avatar, Tooltip } from '@mui/material';
import { UserService } from '../../../services/api';
import { User } from "../../../models/Accounts/User";

interface TaskUserSelectProps {
    user: string;
    onTaskUserChange: (newUser: string) => void;
    permission: boolean;
    show: boolean;
}

const TaskUserSelect: React.FC<TaskUserSelectProps> = ({ user, onTaskUserChange, permission, show }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedOption, setSelectedOption] = useState<string>();
    const [userList, setUserList] = useState<User[]>([]);
    const [disabled, setDisabled] = useState<boolean>(false);

    useEffect(() => {
        setSelectedOption(user || '');
        setDisabled(permission);
    }, [user, permission]);

    const getAllUsers = async () => {
        const users = await UserService.getAll();
        setUserList(users || []);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        getAllUsers();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOptionClick = (option: string) => {
        setSelectedOption(option);
        onTaskUserChange(option);
        handleClose();
    };

    const getAvatarColor = (index: number): { backgroundColor: string; color: string } | undefined => {
        if (index % 3 === 0) {
            return { backgroundColor: "#0b2343", color: "white" };
        } else if (index % 3 === 1) {
            return { backgroundColor: "#6abc45", color: "white" };
        } else if (index % 3 === 2) {
            return { backgroundColor: "#6b6b6b", color: "white" };
        }
        return undefined;
    };

    return (
        <div
            style={{ padding: '5px' }}
            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f0f0f0'}
            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
        >
            <Typography
                variant="body1"
                onClick={handleClick}
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            >
                <Tooltip title={selectedOption || ''}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar
                            sx={{
                                width: 40,
                                height: 40,
                                backgroundColor: getAvatarColor(0)?.backgroundColor,
                                color: getAvatarColor(0)?.color
                            }}
                        >
                            {selectedOption ?
                                selectedOption.split(" ")[0].charAt(0).toUpperCase() +
                                selectedOption.split(" ")[1].charAt(0).toUpperCase()
                                : null}
                        </Avatar>
                        {show ? (
                            <span style={{ marginLeft: '8px' }}>
                                {selectedOption}
                            </span>
                        ) : null}
                    </div>
                </Tooltip>
            </Typography>


            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {userList.map((user, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => handleOptionClick(user.firstname + " " + user.lastname)}
                        disabled={disabled}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar
                                sx={{
                                    width: 40,
                                    height: 40,
                                    backgroundColor: getAvatarColor(index)?.backgroundColor,
                                    color: getAvatarColor(index)?.color
                                }}
                            >
                                {user ? user.firstname.charAt(0).toUpperCase() + user.lastname.charAt(0).toUpperCase() : null}
                            </Avatar>
                            <span style={{ marginLeft: '8px' }}>
                                {user.firstname + " " + user.lastname}
                            </span>
                        </div>
                    </MenuItem>
                ))}
            </Menu>
        </div>

    );

};

export default TaskUserSelect;
