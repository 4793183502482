import React, { useEffect, useState,useCallback } from 'react';
import { Dialog, DialogActions, DialogTitle, IconButton, DialogContent, Card, CardContent, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import CloseIcon from '@mui/icons-material/Close';

import TaskNameField from './TaskNameField';
import TaskStatusField from './TaskStatusField';
import TaskCombinedField from './TaskCombinedField';
import TaskAnswerField from './TaskAnswerField';
import TaskUserDetail from './TaskUserDetail';
import TaskCommentAndHistory from './TaskCommentAndHistory';
import ParentTaskDetailSubtaskTable from './ParentTaskDetailSubtaskTable';
import TaskPriorityField from './TaskPriorityField';
import TaskFileTable from './TaskFileTable';
import TaskFileUploadField from './TaskFileUploadField';
import { Task } from '../../../models/Task/Pano';
import { TaskManagementService } from '../../../services/api';

// Define the interface for props

interface ParentTaskDetailProps {
    open: boolean;
    onClose: () => void;
    taskID: string;
}

const ParentTaskDetail: React.FC<ParentTaskDetailProps> = ({ open, onClose, taskID }) => {

    const [myTask, setMyTask] = useState<Task>();
    const [selectedTaskID, setSelectedTaskID] = useState<string>("");

    const getMyTask = useCallback(async () => {
        if(selectedTaskID !== ""){
            const response = await TaskManagementService.get("task/" + selectedTaskID, "json");
            setMyTask(response);
        }else{
            console.log("task id not found parent task id");
        }

    }, [selectedTaskID]);

    useEffect(() => {
        if (open) {
            setSelectedTaskID(taskID);
            getMyTask();
        }
    }, [taskID, open, getMyTask]);


    const handleFieldChange = (value: any, field: string): void => {
        if (myTask) {
            const updatedTask = { ...myTask, [field]: value };
            setMyTask(updatedTask);
            if (["priority", "status", "responsible", "targetDate", "ticketOwnerManager", "internalAuditor", "independentAuditor", "comments", "documents"].includes(field)) {
                updateTaskOnchangeEvent(updatedTask);
            }
        }
    };

    const updateTaskOnchangeEvent = async (task: Task) => {
        task.lastUpdate = new Date();
        const response = await TaskManagementService.update(task.taskID, task,"task");
        console.log("my response -> ", response);
    };

    const updateTaskIsBlur = async (isBlur: boolean, task: Task) => {
        if (isBlur) {
            task.lastUpdate = new Date();
            const response = await TaskManagementService.update(task.taskID, task,"task");
            console.log("my response -> ", response);
        } else {
            console.log("update yapamazsın");
        }
    };

    const fileUpload = async (file: File) =>{
        const parent = {...myTask}
        if(parent._id){
            const formData = new FormData();
            formData.append("files",file);
            const fileUrl = "file/"+parent.taskID;
            const response = await TaskManagementService.create(formData,fileUrl);
            console.log("my parent task file up response -> ", response);
            setMyTask(response);
        }
    }
    

    return (

        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
            PaperProps={{ style: { height: "100vh", width: "100vw", margin: 10 } }}
        >
            <DialogActions style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <DialogTitle style={{ margin: 0 }}>
                    <div>
                        Task-ID: <span>{myTask?.taskID}</span>
                    </div>
                    <div>
                        Ref No: {myTask?.refNum}
                    </div>
                </DialogTitle>
                <IconButton onClick={() => { onClose() }}>
                    <CloseIcon />
                </IconButton>
            </DialogActions>
            <DialogContent
                style={{
                    maxHeight: "70vh",
                    overflowY: "auto",
                    scrollbarWidth: "thin",
                    scrollbarColor: "#6b6b6b #f1f1f1",
                }}
            >

                <Grid container columns={12}>

                    <Grid container rowSpacing={4} size={7} >

                        <Grid size={10}>
                            <Card>
                                <CardContent>
                                    <Typography variant='h5' color='red'>
                                        Regulation Ref:
                                    </Typography>

                                    <TaskCombinedField
                                        item={myTask?.combinedField || ""}
                                        multiline={true}
                                        rows={3}
                                        label={false}
                                        disabled={false}
                                        onTaskCombinedFieldChange={(newCombinedField) => { handleFieldChange(newCombinedField, "combinedField") }}
                                        onBlurCallBack={(isBlurred) => {
                                            if (myTask) {
                                                updateTaskIsBlur(isBlurred, myTask);
                                            }
                                        }}
                                    />
                                </CardContent>
                            </Card>

                        </Grid>

                        <Grid size={10}>

                            <Card>
                                <CardContent>
                                    <TaskNameField
                                        item={myTask?.name || ""}
                                        multiline={true}
                                        rows={3}
                                        onTaskNameChange={(newValue) => { handleFieldChange(newValue, "name") }}
                                        onBlurCallBack={(isBlurred) => {
                                            if (myTask) {
                                                updateTaskIsBlur(isBlurred, myTask);
                                            }
                                        }}
                                        permission={false}
                                    />
                                </CardContent>
                            </Card>

                        </Grid>

                        <Grid size={10}>

                            <Card>
                                <CardContent>
                                    <TaskAnswerField
                                        item={myTask?.answer || ""}
                                        multiline={true}
                                        rows={3}
                                        onTaskAnswerChange={(newValue) => { handleFieldChange(newValue, "answer") }}
                                        onBlurCallBack={(isBlurred) => {
                                            if (myTask) {
                                                updateTaskIsBlur(isBlurred, myTask);
                                            }
                                        }}
                                        permission={false}
                                    />
                                </CardContent>
                            </Card>

                        </Grid>

                    </Grid>

                    <Grid container size={5} justifyContent="center" >

                        <Grid size={8} sx={{ marginBottom: "30px" }}>
                            <Card square>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid size={6}>
                                            <TaskStatusField
                                                item={myTask?.status || ""}
                                                onStatusChange={(newStatus) => { handleFieldChange(newStatus, "status") }}
                                                allStatus={["Not Assigned", "To Do", "In Progress", "Awaiting Approver", "Awaiting Int. Auditor", "Submitted", "Done", "Cancelled"]}
                                                permission={false}
                                            />
                                        </Grid>
                                        <Grid size={6}>
                                            <TaskPriorityField
                                                item={myTask?.priority || ""}
                                                onPriorityChange={(newPriority) => { handleFieldChange(newPriority, "priority") }}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>


                        <Grid size={10} sx={{ marginTop: "10px" }}>
                            <Card square sx={{ backgroundColor: "whitesmoke" }}>
                                <CardContent>
                                    <TaskUserDetail
                                        selectedTicketOwner={myTask?.responsible || ""}
                                        selectedTicketOwnerManager={myTask?.ticketOwnerManager || ""}
                                        selectedInternalAuditor={myTask?.internalAuditor || ""}
                                        selectedIndependentAuditor={myTask?.independentAuditor || ""}
                                        targetDate={myTask?.targetDate || null}
                                        onTicketOwnerChange={(newTicketOwner) => { handleFieldChange(newTicketOwner, "responsible") }}
                                        onTicketOwnerManagerChange={(newTicketOwnerManager) => { handleFieldChange(newTicketOwnerManager, "ticketOwnerManager") }}
                                        onInternalAuditorChange={(newInternalAuditor) => { handleFieldChange(newInternalAuditor, "internalAuditor") }}
                                        onIndependentAuditorChange={(newIndependentAuditor) => { handleFieldChange(newIndependentAuditor, "independentAuditor") }}
                                        onTargetDateChange={(newTargetDate) => { handleFieldChange(newTargetDate, "targetDate") }}
                                        disabled={false}
                                        requestDate={myTask?.requestDate || null}
                                        creationDate={myTask?.creationDate || null}
                                        lastUpdate={myTask?.lastUpdate || null}
                                    />


                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>

                    <Grid container size={12} sx={{ marginTop: "30px" }}>
                        <Card square>
                            <CardContent>
                                <TaskFileUploadField
                                    type='detail'
                                    allDocuments={myTask?.documents || []}
                                    onFileChange={(newFile) => { fileUpload(newFile) }}
                                    permission={false}
                                />
                                <TaskFileTable
                                    documents={myTask?.documents || []}
                                    onFileChange={(newFiles) => { handleFieldChange(newFiles, "documents") }}
                                />
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid container size={12}>
                        <Grid size={10}>
                            <ParentTaskDetailSubtaskTable subtasks={myTask?.subtasks || []} parentTask={myTask || null} />
                        </Grid>
                    </Grid>
                    <Grid container size={12} sx={{ marginTop: "40px" }}>
                        <Card square>
                            <CardContent>
                                <Grid size={10}>
                                    <TaskCommentAndHistory taskComment={myTask?.comments || []} onCommentAndHistoryChange={(newComments) => { handleFieldChange(newComments, "comments") }} />
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

            </DialogContent>

        </Dialog>

    );
};

export default ParentTaskDetail;