import React, { useState, useEffect } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Document } from '../../../models/Task/Pano';
import { TaskManagementService } from '../../../services/api';
import { GoFileZip } from "react-icons/go";
import { FaFilePdf, FaFileExcel, FaFileWord, FaFilePowerpoint, FaFileImage, FaFile, FaFileCsv } from "react-icons/fa";
import { BsFiletypeTxt } from "react-icons/bs";
import AddCircleIcon from '@mui/icons-material/AddCircle';


interface TaskFileUploadProps {
  onFileChange: (newFile: File) => void;
  allDocuments: Document[];
  type: string;
  permission: boolean;
}

const TaskFileUploadField: React.FC<TaskFileUploadProps> = ({ onFileChange, allDocuments, type, permission }) => {
  const [fileName, setFileName] = useState<string>('');
  const [documents, setDocuments] = useState<Document[]>([]);
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    setDocuments(allDocuments);
    setDisabled(permission);
  }, [allDocuments, permission]);

  const handleButtonClick = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        const file = target.files[0];
        setFileName(file.name);
        onFileChange(file);

        setTimeout(() => {
          setFileName('');
        }, 3000);
      }
    };
    input.click();
  };

  const downloadFile = async (id: string, fileName: string) => {
    try {
      const response = await TaskManagementService.get("file/" + id, 'arraybuffer');
      const data = response;
      if (!data) {
        console.error("Response data is empty.");
        return;
      }
      const fileTypeIndex = fileName.lastIndexOf(".");
      const fileType = fileTypeIndex !== -1 ? fileName.substring(fileTypeIndex + 1) : 'application/octet-stream';
      const byteArray = new Uint8Array(data);
      const blob = new Blob([byteArray], { type: fileType });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("File download error:", error);
    }
  };

  const fileTypes = [
    { type: 'zip', icon: <GoFileZip />, color: '#007BFF' },
    { type: 'pdf', icon: <FaFilePdf />, color: '#E63946' },
    { type: 'xlsx', icon: <FaFileExcel />, color: '#3BB273' },
    { type: 'xls', icon: <FaFileExcel />, color: '#3BB273' },
    { type: 'docx', icon: <FaFileWord />, color: '#2B579A' },
    { type: 'doc', icon: <FaFileWord />, color: '#2B579A' },
    { type: 'pptx', icon: <FaFilePowerpoint />, color: '#C94C4D' },
    { type: 'ppt', icon: <FaFilePowerpoint />, color: '#C94C4D' },
    { type: 'png', icon: <FaFileImage />, color: '#007BFF' },
    { type: 'jpg', icon: <FaFileImage />, color: '#007BFF' },
    { type: 'jpeg', icon: <FaFileImage />, color: '#007BFF' },
    { type: 'gif', icon: <FaFileImage />, color: '#007BFF' },
    { type: 'csv', icon: <FaFileCsv />, color: '#6abc45' },
    { type: 'txt', icon: <BsFiletypeTxt />, color: '#000000' },
  ];

  const getIcon = (type: string) => {
    const styles: React.CSSProperties = {
      fontSize: "24px",
    };
    const fileType = fileTypes.find(file => file.type === type);
    if (fileType) {
      return React.cloneElement(fileType.icon, { style: { ...styles, color: fileType.color } });
    }
    return <FaFile style={{ ...styles, color: "#A9A9A9" }} />;
  };

  const decodeUTF8 = (input: string) => {
    const byteArray = new Uint8Array(input.split('').map(char => char.charCodeAt(0)));
    const decoder = new TextDecoder("utf-8");
    return decoder.decode(byteArray);
  };


  return (
    <div>
      {type === "dashboard" && documents && documents.length > 0 ? (
        <>
          <IconButton onClick={handleButtonClick} sx={{ color: '#0b2343' }} disabled={disabled}>
            <AddCircleIcon sx={{ fontSize: '20px' }} />
          </IconButton>
          {documents.slice(0, 1).map((doc, index) => (
            <Tooltip title={decodeUTF8(doc.fileName)} placement='top' key={index}>
              <IconButton
                onClick={()=>downloadFile(doc.fileID, decodeUTF8(doc.fileName))}>

                {getIcon(doc.fileName.split(".")[1])}
              </IconButton>
            </Tooltip>
          ))}
          {documents.length > 1 && (
            <Tooltip
              placement='top'
              title={
                <ul style={{ margin: 0, padding: '5px' }}>
                  {documents.slice(1).map((doc, index) => (
                    <li key={index} style={{ listStyleType: 'none' }}>
                      {decodeUTF8(doc.fileName)}
                    </li>
                  ))}
                </ul>
              }
            >
              <IconButton >
                <MoreHorizIcon />
              </IconButton>
            </Tooltip>
          )}
          {fileName && <p style={{ margin: '0' }}>Uploaded file name: {fileName}</p>}
        </>
      ) : (
        <>
          <IconButton onClick={handleButtonClick} sx={{ color: '#0b2343' }} disabled={disabled}>
            <AddCircleIcon sx={{ fontSize: '20px' }} />
          </IconButton>
          {fileName && <p style={{ margin: '0' }}>Uploaded file name: {fileName}</p>}
        </>
      )}
    </div>
  );
};

export default TaskFileUploadField;
