import React, { useState } from 'react';

interface WithEditModeProps<T> {
  item?: T | null;
  onSave: (item: T) => void;
}

const withEditMode = <T extends {}>(WrappedComponent: React.ComponentType<WithEditModeProps<T>>) => {
  return (props: WithEditModeProps<T>) => {
    const [editItem, setEditItem] = useState<T | null>(props.item || null);

    const handleSave = (item: T) => {
      props.onSave(item);
      setEditItem(null);
    };

    return <WrappedComponent {...props} item={editItem} onSave={handleSave} />;
  };
};

export default withEditMode;
