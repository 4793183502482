import React from 'react';
import { ThemeConfigModel } from '../../models/DataIntegration/ThemeConfig';
import DynamicForm from '../Common/DynamicForm';
import withEditMode from '../Common/withEditMode';

interface ThemeFormProps {
  item?: ThemeConfigModel | null;
  onSave: (theme: ThemeConfigModel) => void;
}

const ThemeForm: React.FC<ThemeFormProps> = ({ item, onSave }) => {
  const initialValues: ThemeConfigModel = item || { 
    _id: '', 
    themeName: '', 
    creationDate: '', 
    version: '', 
    themeFields: [], 
  };

  const fields = [
    { name: 'themeName' as keyof ThemeConfigModel, label: 'ThemeConfigModel Name' },
    { name: 'creationDate' as keyof ThemeConfigModel, label: 'Creation Date', type: 'date' },
    { name: 'version' as keyof ThemeConfigModel, label: 'Version' },
    { name: 'themeFields' as keyof ThemeConfigModel, label: 'ThemeConfigModel Fields', type: 'json' },
  ];

  return <DynamicForm initialValues={initialValues} fields={fields} onSave={onSave} />;
};

export default withEditMode(ThemeForm);
