import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';


interface TaskNameFieldProps {
  item: string;
  multiline: boolean;
  rows: number;
  onTaskNameChange: (newTaskName: string) => void;
  onBlurCallBack: (isBlurred: boolean) => void;
  permission: boolean;
}

const TaskNameField: React.FC<TaskNameFieldProps> = ({ item, multiline, rows, onTaskNameChange, onBlurCallBack, permission }) => {
  const [taskName, setTaskName] = useState<string>('');
  const [anyChange, setAnyChange] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    setTaskName(item || '');
    setDisabled(permission);
  }, [item,permission]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTaskName(value);
    onTaskNameChange(value);
    setAnyChange(true);
  };

  return (
    <TextField
      fullWidth
      label="Task"
      value={taskName}
      onChange={handleChange}
      multiline={multiline}
      rows={rows}
      disabled={disabled}
      onBlur={()=>{
        onBlurCallBack(true && anyChange);
        setAnyChange(false);
      }}
    />
  );
};

export default TaskNameField;