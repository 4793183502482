import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import axios from 'axios';
import { User } from '../models/Accounts/User';

interface AuthContextType {
  isAuthenticated: boolean;
  authantication_token: string | null;
  role: string | null;
  user: User | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authantication_token, setToken] = useState<string | null>(null);
  const [role, setRole] = useState<string | null>(null); // New role state
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const storedToken = localStorage.getItem('authantication_token');
    const storedRole = localStorage.getItem('role');
    const storedUser = localStorage.getItem('user');
    if (storedToken && storedRole && storedUser) {
      setToken(storedToken);
      setRole(storedRole);
      setIsAuthenticated(true);
    }
  }, []);

  const login = async (email: string, password: string) => {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/api/login`, { email, password });
    if (response.data.authantication_token && response.data.user.role) {
      setToken(response.data.authantication_token);
      setRole(response.data.user.role); // Set role from response
      setUser(response.data.user);
      setIsAuthenticated(true);
      localStorage.setItem('authantication_token', response.data.authantication_token);
      localStorage.setItem('role', response.data.user.role); // Store role in localStorage
      localStorage.setItem('user',response.data.user);
    }
  };

  const logout = () => {
    setToken(null);
    setRole(null);
    setIsAuthenticated(false);
    setUser(null);
    localStorage.removeItem('authantication_token');
    localStorage.removeItem('role');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, authantication_token, role, user ,login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};