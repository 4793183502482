import React from "react";
import { Modal, Typography, Button } from "@mui/material";
import Grid from '@mui/material/Grid2';


interface DeleteModalProps {
    open: boolean;
    onClose: () => void;
    onResponse: (response: boolean) => void;
}

const DeleteModal: React.FC<DeleteModalProps> = ({ open, onClose, onResponse }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Grid container
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 350,
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                    p: 4,
                    boxShadow: 24
                }}
            >
                <Grid size={12} display="flex" justifyContent="center">
                    <Typography variant="body1" gutterBottom>
                        Are you sure you want to delete it?
                    </Typography>

                </Grid>
                <Grid container size={12} display="flex" sx={{ marginTop: 3, marginRight: 5, marginLeft: 5 }}>
                    <Grid size={6} justifyContent="flex-start" display="flex">
                        <Button variant="contained" sx={{ backgroundColor: "#0b2343" }} onClick={() => onResponse(true)}>
                            Yes
                        </Button>
                    </Grid>

                    <Grid size={6} justifyContent="flex-end" display="flex">
                        <Button variant="contained" sx={{ backgroundColor: "#c10101" }} onClick={() => onResponse(false)}>
                            No
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default DeleteModal;
