import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid2';
import { Paper, Button, Table, TableBody, TableHead, TableCell, TableRow, IconButton, Box, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';


import { Project } from "../../models/Task/Pano";
import TaskProjectCreate from "./component/TaskProjectCreate";
import { TaskManagementService } from "../../services/api";
import DeleteModal from "../Common/DeleteModal";

interface TaskProjectManagementTableProps {
    onProjectListChange: (allProject: Project[]) => void;
}

const TaskProjectManagementTable: React.FC<TaskProjectManagementTableProps> = ({onProjectListChange}) => {

    const navigate = useNavigate();
    const [projectList, setProjectList] = useState<Project[]>([]);

    const [projectOpen, setProjectOpen] = useState<boolean>(false);
    const [projectOpenType, setProjectOpenType] = useState<string>("");
    const [selectedProject, setSelectedProject] = useState<Project | null>();
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [selectedCode, setSelectedCode] = useState<string | null>(null);

    const getAllProject = async () => {
        const response = await TaskManagementService.getAll("project");
        setProjectList(response);
        onProjectListChange(response)
    };

    useEffect(() => {
        getAllProject();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const redirectToTask = (projectCode: string) =>{
        navigate("/task-management/project-tasks/"+projectCode);
    };

    const deleteProject = async () => {
        if(selectedCode){
            const response = await TaskManagementService.delete(selectedCode,"project");
            console.log("project delete response -> ", response);
        }
        getAllProject();
    };

    return (
        <Paper>
            <DeleteModal
                open={deleteModalOpen}
                onClose={()=>{setDeleteModalOpen(!deleteModalOpen)}}
                onResponse={(response)=>{
                    if(response){
                        deleteProject();
                        setDeleteModalOpen(!deleteModalOpen);
                    }else{
                        setSelectedCode(null);
                        setDeleteModalOpen(!deleteModalOpen);
                    }
                }}
            />
            <TaskProjectCreate
                open={projectOpen}
                onClose={() => { 
                    setProjectOpen(!projectOpen);
                    getAllProject();}}
                project={selectedProject || null}
                type={projectOpenType}
            />

            <Grid container size={12} sx={{ margin: "15px" }}>
                <Typography variant="h4" sx={{ color: "#0b2343" }}>
                    Project Management
                </Typography>
            </Grid>

            <Grid container sx={{ margin: "30px" }} display="flex" direction="column">
                <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
                    <Button variant="contained" size="small" style={{ backgroundColor: "#0b2343" }} onClick={() => {
                        setProjectOpenType("Create");
                        setProjectOpen(!projectOpen);
                        setSelectedProject(null);
                    }
                    }>
                        Create Project
                    </Button>
                </Box>

                <Grid container size={12}>
                    <Table>
                        <TableHead style={{ backgroundColor: "whitesmoke" }}>
                            <TableRow>
                                <TableCell sx={{ fontWeight: "bold" }}>
                                    Project ID
                                </TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>
                                    Project Name
                                </TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>
                                    Coordinator
                                </TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>
                                    Independent Auditor
                                </TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>
                                    Project Create Date
                                </TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {projectList && projectList.length > 0 ? (
                                projectList.map((project, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{cursor: "pointer"}} onClick={()=>{redirectToTask(project.code)}}>{project.code}</TableCell>
                                        <TableCell style={{cursor: "pointer"}} onClick={()=>{redirectToTask(project.code)}}>{project.name}</TableCell>
                                        <TableCell style={{cursor: "pointer"}} onClick={()=>{redirectToTask(project.code)}}>{project.coordinatorName}</TableCell>
                                        <TableCell style={{cursor: "pointer"}} onClick={()=>{redirectToTask(project.code)}}>{project.independentAuditor}</TableCell>
                                        <TableCell style={{cursor: "pointer"}} onClick={()=>{redirectToTask(project.code)}}>{project.creationDate ? new Date(project.creationDate).toISOString().split("T")[0] : null}</TableCell>
                                        <TableCell>
                                            <Box>
                                                <IconButton onClick={() => {
                                                    setSelectedProject(project);
                                                    setProjectOpenType("Edit");
                                                    setProjectOpen(!projectOpen);
                                                }}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton onClick={() => {setDeleteModalOpen(!deleteModalOpen); setSelectedCode(project.code) }}>
                                                    <DeleteIcon style={{ color: "red" }} />
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={5}>
                                        <Typography align="center">No project could be created</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default TaskProjectManagementTable;
