import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';

// Define the interface for props
interface TaskCombinedFieldProps {
  item: string; // Prop to receive the task name
  multiline: boolean;
  rows: number;
  label: boolean;
  disabled: boolean;
  onTaskCombinedFieldChange: (newCombinedField: string)=>void;
  onBlurCallBack: (isBlurred: boolean) => void;
}

const TaskCombinedField: React.FC<TaskCombinedFieldProps> = ({ item, multiline, rows, label, disabled ,onTaskCombinedFieldChange , onBlurCallBack}) => {
  const [combinedField, setCombinedField] = useState<string>('');
  const [anyChange, setAnyChange] = useState<boolean>(false);


  useEffect(() => {
    setCombinedField(item || '');
  }, [item]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCombinedField(value);
    onTaskCombinedFieldChange(value);
    setAnyChange(true);
  };
  

  return (
    <TextField
      fullWidth
      label={!label ? null : "Regulation Ref."}
      value={combinedField}
      onChange={handleChange}
      multiline={multiline}
      rows={rows}
      disabled={disabled}
      onBlur={()=>{
        onBlurCallBack(true && anyChange);
        setAnyChange(false);
      }}

    />
  );
};

export default TaskCombinedField;