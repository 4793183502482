import React, { useState, useEffect, useCallback } from "react";
import Grid from '@mui/material/Grid2';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';


import FileService from "../../../services/FileService";
import { TaskManagementService } from "../../../services/api";
import { Task } from "../../../models/Task/Pano";

interface TaskHeaderProps {
    projectCode: string;
    onRefresh: (refresh: boolean) => void;
}

const TaskHeader: React.FC<TaskHeaderProps> = ({ projectCode, onRefresh }) => {

    const fileService = new FileService();
    const [message, setMessage] = useState<boolean>(false);
    const [projectName, setProjectName] = useState<string>("");
    const uploadedExcelColumns = ["Ref Num", "Status", "Title", "Task", "Article", "Paragraph", "Request Date", "Contact"];
    const navigate = useNavigate();
    const downloadTask = {
        taskId: "Task-ID",
        refNum: "Ref Num",
        regulationRef: "Regulation Ref.",
        task: "Task",
        requestDate: "Request Date",
        responsible: "Responsible",
        approver: "Approver",
        internalAuditor: "Internal Auditor",
        independentAuditor: "Indepent Auditor",
        priority: "Priority",
        status: "Status",
        creationDate: "Creation Date",
        targetDate: "Target Date",
        answer: "Answer",
        documents: "Docs",
        parentTaskId: "Parent Task-ID"
    };


    const convertExcelDateToDate = (excelDate: number): Date | null => {
        if (excelDate < 1) return null;
        const excelBaseDate = new Date(1900, 0, 1);
        excelBaseDate.setDate(excelBaseDate.getDate() + excelDate - 2);
        return excelBaseDate;
    };

    const readProjectDetails = useCallback(async () => {
        const response = await TaskManagementService.get("project/" + projectCode, "json");
        console.log("task header project detail response -> ", response);
        setProjectName(response.name);
    }, [projectCode]);

    useEffect(() => {
        readProjectDetails();
    }, [readProjectDetails]);

    const handleFileUpload = async (jsonData: any) => {
        if (Array.isArray(jsonData)) {
            for (let index = 0; index < jsonData.length; index++) {
                const item = jsonData[index];

                let valid = true;
                for (const key of Object.keys(item)) {
                    if (!uploadedExcelColumns.includes(key)) {
                        setMessage(true);
                        setTimeout(() => {
                            setMessage(false);
                        }, 5000);
                        valid = false;
                        break;
                    }
                }

                if (!valid) {
                    break;
                }

                if (typeof item === 'object' && item !== null) {
                    let newTask: Task = {} as Task;
                    Object.entries(item).forEach(([key, value]) => {
                        switch (key) {
                            case "Ref Num":
                                newTask.refNum = typeof value === "number" ? value : 0;
                                break;
                            case "Status":
                                newTask.status = typeof value === "string" && value !== "" ? value : "Not Assigned";
                                break;
                            case "Title":
                                newTask.title = typeof value === "string" ? value : "";
                                break;
                            case "Task":
                                newTask.name = typeof value === "string" ? value : "";
                                break;
                            case "Article":
                                newTask.article = typeof value === "number" ? String(value) : "";
                                break;
                            case "Paragraph":
                                newTask.paragraph = typeof value === "number" ? String(value) : "";
                                break;
                            case "Request Date":
                                const date = convertExcelDateToDate(value as number);
                                newTask.requestDate = date;
                                break;
                        }
                    });
                    newTask.creationDate = new Date();
                    newTask.answer = "";
                    newTask.priority = "";
                    newTask.notes = "";
                    newTask.lastUpdate = new Date();
                    newTask.documents = [];
                    newTask.comments = [];
                    newTask.subtasks = [];
                    newTask.timeLine = [];
                    newTask.targetDate = null;
                    newTask.combinedField = newTask.title + "/" + newTask.article + "/" + newTask.paragraph;
                    newTask.projectCode = projectCode || "";

                    const response = await TaskManagementService.create(newTask, "task");
                    console.log("my task create response -> ", response);
                }
            }
            onRefresh(true);
        } else {
            console.log("Expected an array but received:", jsonData);
        }
    };

    const redirectProjectPage = () => {
        navigate("/task-management/project");
    };

    const getAllTask = async () => {
        const url = "task/read-all/" + projectCode;
        const response = await TaskManagementService.getAll(url);
        prepareXlsx(response);
    };

    const prepareDate = (date: Date | null) => {
        if (!date) return "";
        
        const d = new Date(date);
        const day = d.getDate().toString().padStart(2, '0');
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const year = d.getFullYear();
      
        return `${day}.${month}.${year}`;
      };
      
    const prepareXlsx = (allTask: Task[]) => {
        const formattedTasks: { [key: string]: any }[] = [];

        allTask.forEach((task) => {
            const myTask: { [key: string]: any } = {
                [downloadTask.taskId]: task.taskID || "N/A",
                [downloadTask.parentTaskId]: "N/A",
                [downloadTask.refNum]: task.refNum|| "N/A",
                [downloadTask.regulationRef]: task.combinedField|| "N/A",
                [downloadTask.task]: task.name|| "N/A",
                [downloadTask.requestDate]: prepareDate(task.requestDate || null)|| "N/A",
                [downloadTask.responsible]: task.responsible|| "N/A",
                [downloadTask.approver]: task.ticketOwnerManager|| "N/A",
                [downloadTask.internalAuditor]: task.internalAuditor|| "N/A",
                [downloadTask.independentAuditor]: task.independentAuditor|| "N/A",
                [downloadTask.priority]: task.priority|| "N/A",
                [downloadTask.status]: task.status|| "N/A",
                [downloadTask.creationDate]: prepareDate(task.creationDate || null)|| "N/A",
                [downloadTask.targetDate]: prepareDate(task.targetDate || null)|| "N/A",
                [downloadTask.answer]: task.answer|| "N/A",
                [downloadTask.documents]: task.documents.map(doc => doc.fileName).join(", ")|| "N/A",
            };

            formattedTasks.push(myTask);

            if (task.subtasks.length > 0) {
                task.subtasks.forEach((subtask) => {
                    const sub: { [key: string]: any } = {
                        [downloadTask.taskId]: subtask.taskID|| "N/A",
                        [downloadTask.parentTaskId]: task.taskID|| "N/A",
                        [downloadTask.refNum]: task.refNum|| "N/A",
                        [downloadTask.regulationRef]: task.combinedField|| "N/A",
                        [downloadTask.task]: subtask.name|| "N/A",
                        [downloadTask.requestDate]: "N/A",
                        [downloadTask.responsible]: subtask.responsible|| "N/A",
                        [downloadTask.approver]: "N/A",
                        [downloadTask.internalAuditor]: "N/A",
                        [downloadTask.independentAuditor]: "N/A",
                        [downloadTask.priority]: subtask.priority|| "N/A",
                        [downloadTask.status]: subtask.status|| "N/A",
                        [downloadTask.creationDate]: prepareDate(subtask.creationDate || null)|| "N/A",
                        [downloadTask.targetDate]: prepareDate(subtask.targetDate || null)|| "N/A",
                        [downloadTask.answer]: subtask.answer|| "N/A",
                        [downloadTask.documents]: subtask.documents.map(doc => doc.fileName).join(", ")|| "N/A",
                    };
                    formattedTasks.push(sub);
                });
            }
        });

        console.log("Formatted Tasks: ", formattedTasks);
        fileService.jsonToXlsx(formattedTasks, projectName);
    };



    return (
        <Grid container spacing={1} justifyContent="flex-start" size={9} sx={{ mb: 5 }}>
            <Grid size={3} style={{ width: 'fit-content', textAlign: 'left', padding: '8px 16px', backgroundColor: '#f5f5f5', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
                <p style={{ margin: 0, fontSize: 18, color: "#0b2343", fontWeight: 'bold', lineHeight: '1.2' }}>
                    <span style={{ cursor: "pointer" }} onClick={() => { redirectProjectPage() }}>
                        Projects
                    </span> / {projectName}
                </p>
            </Grid>


            <Grid size={3} justifyContent="center" sx={{ marginLeft: "10vw" }}>
                <Button
                    variant="outlined"
                    size='medium'
                    sx={{
                        backgroundColor: "#6b6b6b",
                        color: "white"
                    }}
                    onClick={() => { getAllTask() }}
                >
                    Export Task
                </Button>
            </Grid>

            <Grid size={3} justifyContent="center">
                <>
                    <input
                        type="file"
                        accept=".xlsx, .xls"
                        style={{ display: 'none' }}
                        onChange={(event) => fileService.handleFileChange(event, handleFileUpload)}
                        id="upload-excel"
                        disabled={projectCode === ""}
                    />
                    <label htmlFor="upload-excel">
                        <Button
                            variant="outlined"
                            size="medium"
                            sx={{
                                backgroundColor: "#102240",
                                color: "white",
                                "&.Mui-disabled": {
                                    color: "white",
                                },
                            }}
                            disabled={projectCode === ""}
                            component="span"
                        >
                            Upload Task
                        </Button>
                    </label>
                </>
                {message ?
                    <div>
                        <p style={{ color: "red" }}>Check the columns in the file!</p>
                    </div>
                    : null}
            </Grid>
        </Grid>
    )
}



export default TaskHeader;
