import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { IconButton, Paper, Table, TableBody, TableHead, TableRow, TableCell, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ListIcon from '@mui/icons-material/List';
import AddIcon from '@mui/icons-material/Add';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import Grid from '@mui/material/Grid2';
import { TaskManagementService } from '../../services/api';


//Task Man. Field Components
import TaskNameField from './component/TaskNameField';
import TaskDatePicker from './component/TaskDatePicker';
import TaskStatusField from './component/TaskStatusField';
import TaskAnswerField from './component/TaskAnswerField';
import TaskFileUploadField from './component/TaskFileUploadField';
import ParentTaskDetail from './component/ParentTaskDetail';
import TaskUserSelect from './component/TaskUserSelect';
import SubtaskTable from './component/SubtaskTable';
import TaskCreateComponent from './component/TaskCreateComponent';
import DeleteModal from '../Common/DeleteModal';
import TaskHeader from './component/TaskHeader';
import TaskCombinedField from './component/TaskCombinedField';

//Task Man. Model
import { Task } from '../../models/Task/Pano'

const TaskTable: React.FC = () => {
  const { code } = useParams();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [selectedTask, setSelectedTask] = useState<Task>();
  const [openedSubtaskList, setOpenedSubtaskList] = useState<number[]>([]);
  const [taskDetailOpen, setTaskDetailOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [deletedItem, setDeletedItem] = useState<string>("");

  const addTask = async (newTaskName: string) => {

    const newTask: Partial<Task> = {
      projectCode: code,
      name: newTaskName,
      responsible: '',
      status: 'Not Assigned',
      targetDate: null,
      answer: '',
      ticketOwnerManager: "",
      internalAuditor: "",
      independentAuditor: "",
      article: "",
      paragraph: "",
      combinedField: "",
      priority: '',
      notes: '',
      refNum: 0,
      title: '',
      lastUpdate: new Date(),
      creationDate: new Date(),
      documents: [],
      comments: [],
      subtasks: [],
      timeLine: [null, null],
    };

    const response = await TaskManagementService.create(newTask, "task");
    console.log("my task create response -> ", response);

    getAllTask();
  };

  const getAllTask = useCallback(async () => {
    if (code !== "") {
      const url = "task/read-all/" + code;
      console.log("my all task url ->>", url);
      const allTask = await TaskManagementService.getAll(url);
      console.log("my all task ->>", allTask);
      setTasks([...allTask]);
    } else {
      console.log("No project has been selected");
    }
  }, [code]);

  useEffect(() => {
    getAllTask();
  }, [getAllTask]);

  const addSubtask = async (task: Task) => {

    let newSubtask = {
      name: '',
      responsible: '',
      status: 'Not Assigned',
      targetDate: null,
      answer: '',
      priority: '',
      notes: '',
      lastUpdate: new Date(),
      documents: [],
      comments: [],
      creationDate: new Date(),
    };
    const response = await TaskManagementService.update(task.taskID, newSubtask, "task/subtask");
    console.log("my subtask response -> ", response);
    getAllTask();

  };


  const handleFieldChange = (value: any, index: number, field: string): void => {

    const updatedTask = { ...tasks[index] };
    if (field === "priority") {
      updatedTask.priority = value;
      updateTaskOnchangeEvent(updatedTask);
    } else if (field === "status") {
      updatedTask.status = value;
      updateTaskOnchangeEvent(updatedTask);
    } else if (field === "responsible") {
      updatedTask.responsible = value;
      updateTaskOnchangeEvent(updatedTask);
    } else if (field === "targetDate") {
      updatedTask.targetDate = value;
      updateTaskOnchangeEvent(updatedTask);
    } else if (field === "timeline") {
      updatedTask.timeLine = value;
      updateTaskOnchangeEvent(updatedTask);
    }
    else if (field === "name") {
      updatedTask.name = value;
    } else if (field === "answer") {
      updatedTask.answer = value;
    } else if (field === "notes") {
      updatedTask.notes = value;
    }else if (field === "regu"){
      updatedTask.combinedField= value;
    }

    const allTask = [...tasks];
    allTask[index] = updatedTask;

    setTasks(allTask);

  };

  const uploadFile = async (file: File, task: Task) => {
    const formData = new FormData();
    task.lastUpdate = new Date();
    formData.append('files', file);
    const fileUrl = "file/" + task.taskID;
    const response = await TaskManagementService.create(formData, fileUrl);
    console.log("my file upload response -> ", response);
    getAllTask();
  };

  const updateTaskOnchangeEvent = async (task: Task) => {
    task.lastUpdate = new Date();
    const response = await TaskManagementService.update(task.taskID, task, "task");
    console.log("my response -> ", response);
    getAllTask();
  };

  const updateTaskIsBlur = async (isBlur: boolean, field: string, task: Task) => {

    if (isBlur) {
      task.lastUpdate = new Date();
      const response = await TaskManagementService.update(task.taskID, task, "task");
      console.log("my response -> ", response);
      getAllTask();
    } else {
      console.log("update yapamazsın");
    }

  };

  const deleteTask = async () => {
    const response = await TaskManagementService.delete(deletedItem, "task");
    console.log("task delete response -> ", response);
    getAllTask();
    setDeletedItem("");
    setDeleteModalOpen(!deleteModalOpen);
  };

  const tableStyle = {
    minWidth: "20vh",
    fontWeight: 'bold',
    fontSize: "15px",
  };

  const taskDetailOp = () => {
    getAllTask();
    setTaskDetailOpen(!taskDetailOpen);
  };

  const subTaskOpenClose = (item: number) => {
    const list = [...openedSubtaskList];

    if (list.includes(item)) {
      const updatedList = list.filter((i) => i !== item);
      setOpenedSubtaskList(updatedList);
    }
    else {
      setOpenedSubtaskList([...list, item]);
    }
  };

  const checkUnvisibleField = (fieldName: keyof Task) => {
    return tasks.some((task) => task.taskPermission[fieldName] === "UnvisibleF");
  };

  return (
    <Paper sx={{ p: 2, mb: 2, minWidth: "50vh" }}>
      <ParentTaskDetail
        open={taskDetailOpen}
        onClose={() => {
          taskDetailOp();
        }}
        taskID={selectedTask?.taskID || ""}
      />
      <Grid container>
        <TaskHeader
          projectCode={code || ""}
          onRefresh={(refresh) => {
            if (refresh) {
              getAllTask();
            }
          }}
        />
      </Grid>
      <Grid container rowSpacing={2} columns={12}>
        <Grid size={12}>
          <div style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead sx={{ backgroundColor: "whiteSmoke" }}>
                <TableRow>

                  {!checkUnvisibleField("Task ID" as keyof Task) ? (
                    <TableCell sx={tableStyle}>Task-ID</TableCell>
                  ) : null}
                  {!checkUnvisibleField("Ref. Num." as keyof Task) ? (
                    <TableCell sx={tableStyle}>Ref Num</TableCell>
                  ) : null}

                  {!checkUnvisibleField("Regulation Ref." as keyof Task) ? (
                    <TableCell sx={tableStyle}>Regulation Ref.</TableCell>
                  ) : null}
                  {!checkUnvisibleField("Task" as keyof Task) ? (
                    <TableCell sx={tableStyle}>Task</TableCell>
                  ) : null}
                  {!checkUnvisibleField("Request Date" as keyof Task) ? (
                    <TableCell sx={tableStyle}>Request Date</TableCell>
                  ) : null}
                  {!checkUnvisibleField("Responsible" as keyof Task) ? (
                    <TableCell sx={tableStyle}>Responsible</TableCell>
                  ) : null}
                  {!checkUnvisibleField("Status" as keyof Task) ? (
                    <TableCell sx={tableStyle}>Status</TableCell>
                  ) : null}
                  {!checkUnvisibleField("Creation Date" as keyof Task) ? (
                    <TableCell sx={tableStyle}>Creation Date</TableCell>
                  ) : null}
                  {!checkUnvisibleField("Target Date" as keyof Task) ? (
                    <TableCell sx={tableStyle}>Target Date</TableCell>
                  ) : null}
                  {!checkUnvisibleField("Response" as keyof Task) ? (
                    <TableCell sx={tableStyle}>Response</TableCell>
                  ) : null}
                  {!checkUnvisibleField("Documents" as keyof Task) ? (
                    <TableCell sx={tableStyle}>Docs</TableCell>
                  ) : null}
                  <TableCell sx={tableStyle}>Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {tasks.map((task, index) => (
                  <React.Fragment key={index}>
                    <TableRow style={{ border: "1px black solid" }}>
                      {!checkUnvisibleField("Task ID" as keyof Task) ? (
                        <TableCell sx={{ cursor: "pointer" }} onClick={() => {
                          setSelectedTask(task);
                          taskDetailOp();
                        }}>
                          <p>{task.taskID}</p>
                        </TableCell>) : null}
                      {!checkUnvisibleField("Ref. Num." as keyof Task) ? (
                        <TableCell sx={{ cursor: "pointer" }} onClick={() => {
                          setSelectedTask(task);
                          taskDetailOp();
                        }}>
                          <p>{task.refNum}</p>
                        </TableCell>) : null}

                      {!checkUnvisibleField("Regulation Ref." as keyof Task) ? (
                        <TableCell>
                          <TaskCombinedField
                            item={task.combinedField}
                            multiline={false}
                            rows={1}
                            label={true}
                            disabled={task.taskPermission.permissions["Regulation Ref."] === "Editable" ? false : true}
                            onTaskCombinedFieldChange={(newValue) => { handleFieldChange(newValue, index, "regu") }}
                            onBlurCallBack={(isBlurred) => { updateTaskIsBlur(isBlurred, "regu", task) }}

                          />
                        </TableCell>
                      ) : null}

                      {!checkUnvisibleField("Task" as keyof Task) ? (
                        <TableCell>
                          <TaskNameField item={task.name}
                            multiline={false}
                            rows={1}
                            onTaskNameChange={(newValue) => { handleFieldChange(newValue, index, "name") }}
                            onBlurCallBack={(isBlurred) => { updateTaskIsBlur(isBlurred, "name", task) }}
                            permission={task.taskPermission.permissions["Task"] === "Editable" ? false : true}
                          />
                        </TableCell>
                      ) : null}

                      {!checkUnvisibleField("Request Date" as keyof Task) ? (
                        <TableCell sx={{ cursor: "pointer" }} onClick={() => {
                          setSelectedTask(task);
                          taskDetailOp();
                        }}>
                          <p style={{ fontSize: 16 }}>
                            {task.requestDate
                              ? `${new Date(task.requestDate).getDate().toString().padStart(2, '0')}.${(new Date(task.requestDate).getMonth() + 1).toString().padStart(2, '0')}.${new Date(task.requestDate).getFullYear()}`
                              : ""}
                          </p>
                        </TableCell>
                      ) : null}
                      {!checkUnvisibleField("Responsible" as keyof Task) ? (
                        <TableCell>
                          <TaskUserSelect
                            user={task.responsible}
                            onTaskUserChange={(newUser) => { handleFieldChange(newUser, index, "responsible") }}
                            permission={task.taskPermission.permissions["Responsible"] === "Editable" ? false : true}
                            show={false}
                          />
                        </TableCell>
                      ) : null}


                      {!checkUnvisibleField("Status" as keyof Task) ? (
                        <TableCell>
                          <TaskStatusField
                            item={task.status}
                            onStatusChange={(newStatus) => { handleFieldChange(newStatus, index, "status") }}
                            allStatus={task.statusTransitions.map((status) => status.newStatus)}
                            permission={task.taskPermission.permissions["Status"] === "Editable" ? false : true}
                            />
                        </TableCell>
                      ) : null}

                      {!checkUnvisibleField("Creation Date" as keyof Task) ? (
                        <TableCell sx={{ cursor: "pointer" }} onClick={() => {
                          setSelectedTask(task);
                          taskDetailOp();
                        }}>
                          <p style={{ fontSize: 16 }}>
                            {task.creationDate
                              ? `${new Date(task.creationDate).getDate().toString().padStart(2, '0')}.${(new Date(task.creationDate).getMonth() + 1).toString().padStart(2, '0')}.${new Date(task.creationDate).getFullYear()}`
                              : ""}
                          </p>
                        </TableCell>
                      ) : null}

                      {!checkUnvisibleField("Target Date" as keyof Task) ? (
                        <TableCell>
                          <TaskDatePicker
                            date={task.targetDate}
                            onTaskDateChange={(newDate) => { handleFieldChange(newDate, index, "targetDate") }}
                            minDate={task.creationDate || new Date()}
                            permission={task.taskPermission.permissions["Target Date"] === "Editable" ? false : true}
                          />
                        </TableCell>
                      ) : null}

                      {!checkUnvisibleField("Response" as keyof Task) ? (
                        <TableCell>
                          <TaskAnswerField
                            item={task.answer}
                            multiline={false} rows={1}
                            onTaskAnswerChange={(newValue) => { handleFieldChange(newValue, index, "answer"); }}
                            onBlurCallBack={(isBlurred) => { updateTaskIsBlur(isBlurred, "answer", task); }}
                            permission={task.taskPermission.permissions["Response"] === "Editable" ? false : true}
                          />
                        </TableCell>
                      ) : null}

                      {!checkUnvisibleField("Documents" as keyof Task) ? (
                        <TableCell>
                          <TaskFileUploadField
                            allDocuments={task.documents}
                            onFileChange={(newFile) => { uploadFile(newFile, task); }}
                            type='dashboard'
                            permission={task.taskPermission.permissions["Documents"] === "Editable" ? false : true}
                          />
                        </TableCell>
                      ) : null}

                      <TableCell>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton onClick={() => {
                              setDeleteModalOpen(true);
                              setDeletedItem(task.taskID);
                            }}>
                              <DeleteIcon sx={{ color: 'red' }} />
                            </IconButton>
                            <IconButton onClick={() => {
                              setSelectedTask(task);
                              taskDetailOp();
                            }}>
                              <ListIcon sx={{ color: 'gray' }} />
                            </IconButton>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton onClick={() => {
                              addSubtask(task);
                              if (!openedSubtaskList.includes(index)) {
                                subTaskOpenClose(index);
                                getAllTask();
                              }
                            }} sx={{ color: 'gray' }}>
                              <AddIcon />
                            </IconButton>
                            <IconButton onClick={() => {
                              subTaskOpenClose(index);
                              getAllTask();
                            }} sx={{ color: 'gray' }}>
                              <ImportExportIcon />
                            </IconButton>
                          </Box>
                        </Box>

                      </TableCell>
                    </TableRow>
                    {openedSubtaskList.includes(index) && (
                      <TableRow>
                        <TableCell colSpan={14} sx={{ padding: 0 }}>
                          <SubtaskTable parentTask={task} onRefresh={(refresh) => {
                            if (refresh) {
                              getAllTask();
                            }
                          }} />
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </div>
        </Grid>

        <Grid size={12}>
          <TaskCreateComponent onAddTask={(taskName) => { addTask(taskName); }} />
        </Grid>
      </Grid>

      <DeleteModal
        open={deleteModalOpen}
        onClose={() => { setDeleteModalOpen(!deleteModalOpen) }}
        onResponse={(response) => {
          if (response) {
            deleteTask();
          } else {
            setDeletedItem("");
            setDeleteModalOpen(!deleteModalOpen);
          }
        }}

      />
    </Paper>

  );
};

export default TaskTable;
