import HandlerService from './HandlerService';
import { ResponseType } from 'axios';


const createService = (resource: string) => ({
  getAll: async (url = "") => {
    const endpoint = url ? `/${resource}/${url}` : `/${resource}`;
    return await HandlerService.getAll(endpoint);
  },
  create: async (data: any,url = "") => {
    const endpoint = url ? `/${resource}/${url}` : `/${resource}`;
    console.log("my endpoint api - >", url);
    return await HandlerService.post(`${endpoint}`, data);
  },
  update: async (id: string, data: any, url="") => {
    const endpoint = url ? `/${resource}/${url}/${id}` : `/${resource}/${id}`;
    return await HandlerService.put(`${endpoint}`, data);
  },
  delete: async (id: string, url="") => {
    const endpoint = url ? `/${resource}/${url}/${id}` : `/${resource}/${id}`;
    console.log("my delte url -> ", endpoint);
    return await HandlerService.delete(`${endpoint}`);
  },
  get: async (url: string, responseType: ResponseType) => {
    console.log("get url -> ", url);
    return await HandlerService.get(`/${resource}/${url}`,responseType);
  }
});

export const UserService = createService('auth/api/users');
export const RoleService = createService('auth/api/roles');
export const PermissionService = createService('auth/api/permissions');
export const ConfigThemeService = createService('data-integration/api/themes');
export const TaskManagementService = createService('tasks/api');