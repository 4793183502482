import React, { useState, useEffect } from 'react';
import { TextField } from "@mui/material";

interface TaskDatePickerProps {
  date: Date | null;
  onTaskDateChange: (newDate: Date | null) => void;
  minDate: Date | null;
  permission: boolean;
}

const TaskNameField: React.FC<TaskDatePickerProps> = ({ date, onTaskDateChange, minDate, permission }) => {
  const [targetDate, setTargetDate] = useState<Date | null>(date || new Date());
  const [message,setMessage] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    setTargetDate(date || null);
    setDisabled(permission);
  }, [date,permission]);

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value ? new Date(event.target.value) : null;


    if (value && minDate && value <= new Date(minDate)) {
      setTargetDate(null);
      onTaskDateChange(null);
      setMessage(true);
      setTimeout(() => setMessage(false), 5000); 
    } else {
      setTargetDate(value);
      onTaskDateChange(value);
    }
  };

  return (
    <>
      <TextField
        type="date"
        value={targetDate ? new Date(targetDate).toISOString().slice(0, 10) : ""}
        onChange={handleStartDateChange}
        variant="standard"
        slotProps={{
          input: {
            disableUnderline: true,
          },
        }}
        disabled={disabled}
      />
      {message ? (
        <p style={{fontSize: 12, color: "red"}}>The target date must not be smaller than the creation date!</p>
      ):null}
    </>

  );
};

export default TaskNameField;
