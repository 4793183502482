import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

interface ProtectedRouteProps {
  element: React.ReactElement;
  requiredRole?: string[]; // Optional requiredRole prop
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, requiredRole }) => {
  const { isAuthenticated, role } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // Ensure role is not null before using it
  if (requiredRole && (!role || !requiredRole.includes(role))) {
    return <Navigate to="/dashboard" />; // Redirect if role doesn't match
  }

  return element;
};

export default ProtectedRoute;