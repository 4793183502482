import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';

interface TaskAnsweFieldProps {
  item: string;
  multiline: boolean;
  rows: number;
  onTaskAnswerChange: (newTaskName: string) => void;
  onBlurCallBack: (isBlurred: boolean) => void;
  permission: boolean;
}

const TaskAnswerField: React.FC<TaskAnsweFieldProps> = ({ item, multiline, rows, onTaskAnswerChange, onBlurCallBack,permission }) => {
  const [taskAnswer, setTaskAnswer] = useState<string>('');
  const [anyChange, setAnyChange] = useState<boolean>(false);
  const [disabled,setDisabled] = useState<boolean>(false);


  useEffect(() => {
    setTaskAnswer(item || '');
    setDisabled(permission);
  }, [item,permission]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTaskAnswer(value);
    onTaskAnswerChange(value);
    setAnyChange(true);
  };

  return (
    <TextField
      fullWidth
      label="Response"
      value={taskAnswer}
      onChange={handleChange}
      multiline={multiline}
      rows={rows}
      onBlur={()=>{
        onBlurCallBack(true && anyChange);
        setAnyChange(false);
      }}
      disabled={disabled}
    />
  );
};

export default TaskAnswerField;