import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableHead, TableRow, TableCell, Card, CardContent } from '@mui/material';
import Grid from '@mui/material/Grid2';
import SubtaskDetail from './SubtaskDetail';
import { TaskManagementService } from '../../../services/api';

import { Subtask, Task } from '../../../models/Task/Pano';
import TaskStatusField from './TaskStatusField';

interface SubtaskProps {
    subtasks: Subtask[];
    parentTask: Task | null;
}


const ParentTaskDetailSubtaskTable: React.FC<SubtaskProps> = ({ subtasks, parentTask }) => {

    const [subtaskList, setSubtaskList] = useState<Subtask[]>();
    const [subtaskOpen, setSubtaskOpen] = useState(false);
    const [selectedSubtask, setSelectedSubtask] = useState<Subtask>();
    const [selectedSubtaskIndex, setSelectedSubtaskIndex] = useState<number>(0);
    const [parent, setParent] = useState<Task | null>();

    useEffect(() => {
        setSubtaskList(subtasks || []);
        setParent(parentTask);
    }, [subtasks, parentTask]);


    const subtaskOpenFun = () => {
        setSubtaskOpen(!subtaskOpen);
    };

    const updateSubtask = async ( index: number, value: any) => {
        const parent = {...parentTask};
        const allSubtask = parent.subtasks;

        if(allSubtask && parent.taskID){
            allSubtask[index].status = value;
            parent.subtasks = allSubtask;
            const response = await TaskManagementService.update(parent.taskID,parent,"task");
            console.log("my subtask status respnose -> ", response);
        }else{
            console.log("parent task not available");
        }
    }

    return (

        <Grid size={11} container display={"flex"} sx={{ marginTop: "20px" }}>
            <SubtaskDetail
                open={subtaskOpen}
                onClose={() => { subtaskOpenFun() }}
                subTask={selectedSubtask || undefined}
                parent={parent || undefined}
                subtaskIndex={selectedSubtaskIndex}
                onChangeSubTask={(newSubTaskList) => { setSubtaskList(newSubTaskList) }}
            />

            <Card square sx={{ minWidth: "33vw" }}>
                <CardContent>
                    <Grid size={6} sx={{ minWidth: "33vw" }}>
                        <Table size="small">
                            <TableHead sx={{ backgroundColor: "whitesmoke", borderBottom: "1.5px black solid" }}>
                                <TableRow>
                                    <TableCell style={{ fontWeight: "bold", fontSize: "15px" }}>Subtask-ID</TableCell>
                                    <TableCell style={{ fontWeight: "bold", fontSize: "15px" }}>Subtask</TableCell>
                                    <TableCell style={{ fontWeight: "bold", fontSize: "15px" }}>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {subtaskList && subtaskList.length > 0 ? (subtaskList?.map((subtask, index) => (
                                    <TableRow key={index} >
                                        <TableCell style={{ cursor: "pointer" }} onClick={() => {
                                            subtaskOpenFun();
                                            setSelectedSubtask(subtask);
                                            setSelectedSubtaskIndex(index);
                                        }}>
                                            <p>{subtask.taskID}</p>
                                        </TableCell>
                                        <TableCell style={{ cursor: "pointer" }} onClick={() => {
                                            subtaskOpenFun();
                                            setSelectedSubtask(subtask);
                                            setSelectedSubtaskIndex(index);
                                        }}>
                                            <p>{subtask.name}</p>
                                        </TableCell>
                                        <TableCell>
                                            <TaskStatusField 
                                                item={subtask.status}
                                                onStatusChange={(newStatus) => { updateSubtask(index,newStatus)}}
                                                allStatus={["Not Assigned", "To Do", "In Progress", "Done", "Cancelled"]}
                                                permission={true}
                                                 />
                                        </TableCell>
                                    </TableRow>
                                ))) : <TableRow>
                                    <TableCell>
                                        <p>No subtask available</p>
                                    </TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default ParentTaskDetailSubtaskTable;