import React from 'react';
import { User } from '../../models/Accounts/User';
import { Permission } from '../../models/Accounts/Permission';
import DynamicForm from '../Common/DynamicForm';
import withEditMode from '../Common/withEditMode';

interface UserFormProps {
  item?: User | null;
  onSave: (user: User) => void;
}

const UserForm: React.FC<UserFormProps> = ({ item, onSave }) => {

  const permissionData: Permission = {
    name: "",
    description: "",
    permission: []
  }
  // Adjusting initial values to match the User model
  const initialValues: User = item || { 
    _id: '', 
    firstname: '', 
    lastname: '', 
    email: '', 
    password: '', 
    role: { 
      name: '', 
      permission: permissionData
    },
    company: '', 
    createdAt: '', 
    updatedAt: '' 
  };

  const fields = [
    { name: 'firstname' as keyof User, label: 'First Name' },
    { name: 'lastname' as keyof User, label: 'Last Name' },
    { name: 'email' as keyof User, label: 'Email' },
    { name: 'password' as keyof User, label: 'Password', type: 'password' },
    { name: 'role' as keyof User, label: 'Role', type: 'select', options: ['admin', 'user', 'guest'] },
    { name: 'company' as keyof User, label: 'Company' },
  ];

  return <DynamicForm initialValues={initialValues} fields={fields} onSave={onSave} />;
};

export default withEditMode(UserForm);