import React from "react";
import ManagementTable from "../components/Common/ManagementTable";
import { TenantModel } from "../models/Tenant/Tenant";
import TenantForm from "../components/Tenant/TenantForm";
import { ConfigThemeService } from "../services/api";

const Tenant: React.FC = () => {
    const columns = [
        { label: 'Tenant Name', accessor: 'name' as keyof TenantModel },
        { label: 'Tenant Admin', accessor: 'admin' as keyof TenantModel },
      ];

    return (
        <ManagementTable
            title="Tenant"
            service={ConfigThemeService}
            columns={columns}
            FormComponent={TenantForm}

        />

    )
}


export default Tenant;