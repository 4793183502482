import React from 'react';
import { User } from '../../models/Accounts/User';
import UserForm from '../../components/AccountsManagement/UserForm';
import { UserService } from '../../services/api';
import ManagementTable from '../../components/Common/ManagementTable';

const UserManagement: React.FC = () => {
  const columns = [
    { label: 'First Name', accessor: 'firstname' as keyof User },
    { label: 'Last Name', accessor: 'lastname' as keyof User },
    { label: 'Email', accessor: 'email' as keyof User },
    { label: 'Role', accessor: 'role.name' as keyof User },
    { label: 'Company', accessor: 'company' as keyof User },
  ];

  return (
    <ManagementTable
      title="User"
      service={UserService}
      columns={columns}
      FormComponent={UserForm}
    />
  );
};

export default UserManagement;
