import React from 'react';
import { Permission } from '../../models/Accounts/Permission';
import PermissionForm from '../../components/AccountsManagement/PermissionForm';
import { PermissionService } from '../../services/api';
import ManagementTable from '../../components/Common/ManagementTable';

const PermissionManagement: React.FC = () => {
  const columns = [
    { label: 'Permission Name', accessor: 'name' as keyof Permission },
    { label: 'Permission Description', accessor: 'description' as keyof Permission },
  ];

  return (
    <ManagementTable
      title="Permission"
      service={PermissionService}
      columns={columns}
      FormComponent={PermissionForm}
    />
  );
};

export default PermissionManagement;
