import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableHead, TableRow, TableCell, IconButton, } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ListIcon from '@mui/icons-material/List';
import Grid from '@mui/material/Grid2';

import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';

import TaskNameField from './TaskNameField';
import TaskUserSelect from './TaskUserSelect';
import TaskStatusField from './TaskStatusField';
import TaskPriorityField from './TaskPriorityField';
import TaskAnswerField from './TaskAnswerField';
import TaskDatePicker from './TaskDatePicker';
import TaskFileUploadField from './TaskFileUploadField';
import DeleteModal from '../../Common/DeleteModal';
import SubtaskDetail from './SubtaskDetail';
import { Subtask } from '../../../models/Task/Pano';
import { Task } from '../../../models/Task/Pano';
import { TaskManagementService } from '../../../services/api';

interface SubtaskProps {
    parentTask: Task;
    onRefresh: (refresh: boolean) => void;
}


const SubtaskTable: React.FC<SubtaskProps> = ({ parentTask, onRefresh }) => {

    const [task, setTask] = useState<Task>();
    const [subtaskList, setSubtask] = useState<Subtask[]>([]);
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [selectedDeleteIndex, setSelectedDeleteIndex] = useState<number | null>(null);

    const [subtaskOpen, setSubtaskOpen] = useState<boolean>(false);
    const [selectedSubtask, setSelectedSubtask] = useState<Subtask>();
    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    useEffect(() => {
        setTask(parentTask);
        setSubtask(parentTask.subtasks || [])
    }, [parentTask]);



    const handleSubtaskFieldChange = (value: any, index: number, field: string): void => {

        const allSubtask = [...subtaskList];

        if (task?._id) {

            if (field === "name") {
                allSubtask[index].name = value;
                setSubtask(allSubtask);
            } else if (field === "answer") {
                allSubtask[index].answer = value;
                setSubtask(allSubtask);
            } else if (field === "responsible") {
                allSubtask[index].responsible = value;
                setSubtask(allSubtask);
                handleUpdate(allSubtask, task._id);
            } else if (field === "status") {
                allSubtask[index].status = value;
                setSubtask(allSubtask);
                handleUpdate(allSubtask, task._id);
            } else if (field === "priority") {
                allSubtask[index].priority = value;
                setSubtask(allSubtask);
                handleUpdate(allSubtask, task._id);
            } else if (field === "targetDate") {
                allSubtask[index].targetDate = value;
                setSubtask(allSubtask);
                handleUpdate(allSubtask, task._id);
            }
        }
        else {
            console.error("Task ID is undefined");
        }

    };

    const handleUpdate = async (newSubtask: Subtask[], _id: string) => {

        if (task && newSubtask) {
            const updatedTask = { ...task, subtasks: [...subtaskList] };
            updatedTask.lastUpdate = new Date();
            const response = await TaskManagementService.update(updatedTask.taskID, updatedTask, "task");
            console.log("my response subask update -> ", response);
        }
        else {
            console.log("task not found");
        }
    };

    const updateSubTask = async (isBlur: boolean) => {
        console.log("is blur ->", isBlur);

        if (isBlur) {
            const updatedTask = { ...task, subtasks: [...subtaskList] };
            updatedTask.lastUpdate = new Date();

            if (updatedTask.taskID) {
                const response = await TaskManagementService.update(updatedTask.taskID, updatedTask, "task");
                console.log(" subtask update response -> ", response);
            } else {
                console.log("parent task id not found");
            }
        } else {
            console.log("you cant update")
        }


    };

    const deleteSubtask = async () => {
        const index = selectedDeleteIndex;
        const newSubtaskList = subtaskList.filter((_, i) => i !== index);
        setSubtask(newSubtaskList);

        const parentTask = { ...task, subtasks: newSubtaskList };
        if (parentTask.taskID && parentTask) {
            const response = await TaskManagementService.update(parentTask.taskID, parentTask, "task");
            console.log("subtask delete response -> ", response);
            setSelectedDeleteIndex(null);
            onRefresh(true);
        } else {
            console.log("parent task id not found");
            setSelectedDeleteIndex(null);
        }
    };

    const uploadSubtaskFile = async (file: File, subtaskId: string) => {
        const formData = new FormData();
        formData.append('files', file);
        const fileUrl = "file/" + task?.taskID + "/" + subtaskId;
        const response = await TaskManagementService.create(formData, fileUrl);
        setSubtask(response.subtasks);
        onRefresh(true);
        console.log("my file subtask upload response -> ", response);
    };

    const HeadStyle = {
        fontWeight: 'bold',
        fontSize: "13px",
        maxWidth: "10vh",
    };

    const subtaskOpenFun = () => {
        setSubtaskOpen(!subtaskOpen);
        onRefresh(true);
    };

    return (
        <Grid size={11} display="flex" sx={{ marginLeft: "4%" }}>
            <DeleteModal
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(!deleteModalOpen)}
                onResponse={(response) => {
                    if (response) {
                        deleteSubtask();
                        setDeleteModalOpen(!deleteModalOpen);
                    } else {
                        setDeleteModalOpen(!deleteModalOpen);
                        setSelectedDeleteIndex(null);
                    }
                }}
            />
            <SubtaskDetail
                open={subtaskOpen}
                onClose={() => { subtaskOpenFun() }}
                subTask={selectedSubtask || undefined}
                parent={parentTask || undefined}
                subtaskIndex={selectedIndex}
                onChangeSubTask={(newSubTaskList) => { setSubtask(newSubTaskList) }}
            />

            <SubdirectoryArrowRightIcon fontSize="large" />

            <Table size="small" style={{ backgroundColor: "whitesmoke" }}>
                <TableHead sx={{ backgroundColor: "whitesmoke" }}>
                    <TableRow>
                        <TableCell sx={HeadStyle}>Subtask-ID</TableCell>
                        <TableCell sx={HeadStyle}>Subtask</TableCell>
                        <TableCell sx={HeadStyle}>Priority</TableCell>
                        <TableCell sx={HeadStyle}>Responsible</TableCell>
                        <TableCell sx={HeadStyle}>Status</TableCell>
                        <TableCell sx={HeadStyle}>Creation Date</TableCell>
                        <TableCell sx={HeadStyle}>Target Date</TableCell>
                        <TableCell sx={HeadStyle}>Answer</TableCell>
                        <TableCell sx={HeadStyle}>Document</TableCell>
                        <TableCell sx={HeadStyle}>Actions</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {subtaskList?.map((subtask, index) => (
                        <TableRow key={index}>
                            <TableCell style={{ cursor: "pointer" }} onClick={() => {
                                setSelectedSubtask(subtask);
                                setSelectedIndex(index);
                                subtaskOpenFun();
                            }}>
                                <p>{subtask.taskID}</p>
                            </TableCell>
                            <TableCell>
                                <TaskNameField item={subtask.name}
                                    rows={1}
                                    multiline={false}
                                    onTaskNameChange={(newValue) => { handleSubtaskFieldChange(newValue, index, "name") }}
                                    onBlurCallBack={(isBlurred) => { updateSubTask(isBlurred) }}
                                    permission={false}
                                />
                            </TableCell>
                            <TableCell>
                                <TaskPriorityField item={subtask.priority} onPriorityChange={(newPriority) => { handleSubtaskFieldChange(newPriority, index, "priority") }} />
                            </TableCell>
                            <TableCell>
                                <TaskUserSelect 
                                    user={subtask.responsible}
                                    onTaskUserChange={(newUser) => { handleSubtaskFieldChange(newUser, index, "responsible") }}
                                    permission={false}
                                    show={false}
                                    />
                            </TableCell>
                            <TableCell>
                                <TaskStatusField
                                    item={subtask.status}
                                    onStatusChange={(newStatus) => { handleSubtaskFieldChange(newStatus, index, "status") }}
                                    allStatus={["Not Assigned", "To Do", "In Progress", "Done", "Cancelled"]}
                                    permission={true}
                                />
                            </TableCell>
                            <TableCell style={{ cursor: "pointer" }} onClick={() => {
                                setSelectedSubtask(subtask);
                                setSelectedIndex(index);
                                subtaskOpenFun();
                            }}>
                                <p style={{ fontSize: 16 }}>
                                    {subtask.creationDate
                                        ? `${new Date(subtask.creationDate).getDate().toString().padStart(2, '0')}.${(new Date(subtask.creationDate).getMonth() + 1).toString().padStart(2, '0')}.${new Date(subtask.creationDate).getFullYear()}`
                                        : ""}
                                </p>
                            </TableCell>
                            <TableCell>
                                <TaskDatePicker 
                                    date={subtask.targetDate}
                                    onTaskDateChange={(newDate) => { handleSubtaskFieldChange(newDate, index, "targetDate") }}
                                    minDate={subtask.creationDate || new Date()}
                                    permission={false}
                                    />
                            </TableCell>
                            <TableCell>
                                <TaskAnswerField
                                    item={subtask.answer}
                                    rows={1} multiline={false}
                                    onTaskAnswerChange={(newValue) => { handleSubtaskFieldChange(newValue, index, "answer") }}
                                    onBlurCallBack={(isBlurred) => { updateSubTask(isBlurred) }}
                                    permission={false}
                                />
                            </TableCell>
                            <TableCell>
                                <TaskFileUploadField
                                    allDocuments={subtask.documents}
                                    onFileChange={(newFile) => { uploadSubtaskFile(newFile, subtask.taskID) }}
                                    type='dashboard'
                                    permission={false}
                                />
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={() => {
                                    setDeleteModalOpen(!deleteModalOpen);
                                    setSelectedDeleteIndex(index);
                                }}>
                                    <DeleteIcon sx={{ color: "red" }} />
                                </IconButton>
                                <IconButton onClick={() => {
                                    setSelectedSubtask(subtask);
                                    setSelectedIndex(index);
                                    subtaskOpenFun();
                                }}>
                                    <ListIcon sx={{ color: 'gray' }} />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <SubdirectoryArrowLeftIcon fontSize="large" />
        </Grid>


    );
};

export default SubtaskTable;