import React, { useState, useEffect } from 'react';
import { Select, MenuItem } from '@mui/material';


interface TaskStatusFieldProps {
    item: string;
    onStatusChange: (newStatus: string) => void;
    allStatus: string[];
    permission: boolean;
}

const TaskStatusField: React.FC<TaskStatusFieldProps> = ({ item, onStatusChange, allStatus, permission }) => {
    const [taskStatus, setTaskStatus] = useState<string>('');
    const [statusList, setStatusList] = useState<string[]>([]);
    const [disabled,setDisabled] = useState<boolean>(false);

    useEffect(() => {
        setTaskStatus(item || '');
        setStatusList(allStatus);
        setDisabled(permission);
    }, [item, allStatus, permission]);

    const getColor = (value: string): { backgroundColor: string; color: string } | undefined => {
        if (value === "Done") {
            return { backgroundColor: "#377d51", color: "#FFFFFF" };
        } else if (value === "Not Assigned") {
            return { backgroundColor: "#F0F0F0", color: "#000000" };
        } else if (value === "Cancelled") {
            return { backgroundColor: "#FF0000", color: "#FFFFFF" };
        } else if (value === "In Progress") {
            return { backgroundColor: "#FFFACD", color: "#000000" };
        } else if (value === "Awaiting Int. Auditor") {
            return { backgroundColor: "#f3b056", color: "#000000" };
        } else if (value === "Submitted") {
            return { backgroundColor: "#5ac57b", color: "#FFFFFF" };
        } else if (value === "Awaiting Approver") {
            return { backgroundColor: "#F0E68C", color: "#000000" };
        } else if (value === "To Do") {
            return { backgroundColor: "#FFFFE0", color: "#000000" };
        }
        return undefined;
    };

    return (
        <Select
            fullWidth
            value={taskStatus}
            label="Task Status"
            onChange={(e) => {
                setTaskStatus(e?.target.value);
                onStatusChange(e?.target.value);
            }}
            style={getColor(taskStatus)}
            disabled={disabled}
        >
            <MenuItem key={-1} value={taskStatus} style={getColor(taskStatus)} disabled={true}>
                {taskStatus}
            </MenuItem>
            {

                statusList.map((status, index) => (
                    <MenuItem key={index} value={status} style={getColor(status)}>
                        {status}
                    </MenuItem>
                ))
            }


        </Select>
    );
};

export default TaskStatusField;