import React, { useState } from 'react';
import { TextField, Button, Checkbox, FormControlLabel, FormGroup, MenuItem } from '@mui/material';
import Grid from '@mui/material/Grid2';


interface DynamicFormProps<T> {
  initialValues: T;
  fields: Array<{ name: keyof T | string; label: string; type?: string; options?: string[] }>;
  onSave: (values: T) => void;
}

const DynamicForm = <T extends {}>({ initialValues, fields, onSave }: DynamicFormProps<T>) => {
  const [values, setValues] = useState<T>(initialValues);

  const handleChange = (name: keyof T | string, value: any) => {
    setValues({ ...values, [name as keyof T]: value });
  };

  const handleSubmit = () => {
    onSave(values);
  };

  const renderField = (field: { name: keyof T | string; label: string; type?: string; options?: string[] }) => {
    if (field.type === 'checkbox') {
      return (
        <FormGroup key={field.name as string}>
          {field.options?.map(option => (
            <FormControlLabel
              key={option}
              control={
                <Checkbox
                  checked={
                    Array.isArray(values[field.name as keyof T]) &&
                    (values[field.name as keyof T] as unknown as string[]).includes(option)
                  }
                  onChange={() =>
                    handleChange(
                      field.name,
                      Array.isArray(values[field.name as keyof T]) &&
                        (values[field.name as keyof T] as unknown as string[]).includes(option)
                        ? (values[field.name as keyof T] as unknown as string[]).filter((item: string) => item !== option)
                        : [...(values[field.name as keyof T] as unknown as string[] || []), option]
                    )
                  }
                />
              }
              label={option}
            />
          ))}
        </FormGroup>
      );
    } else if (field.type === 'select') {
      return (
        <TextField
          key={field.name as string}
          select
          label={field.label}
          value={values[field.name as keyof T] as unknown as string}
          onChange={(e) => handleChange(field.name, e.target.value)}
          fullWidth
          margin="normal"
        >
          {field.options?.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      );
    } else {
      return (
        <TextField
          key={field.name as string}
          label={field.label}
          value={values[field.name as keyof T] as unknown as string}
          onChange={(e) => handleChange(field.name, e.target.value)}
          fullWidth
          margin="normal"
          type={field.type || 'text'}
        />
      );
    }
  };

  return (
    <Grid container size={12}>
      <Grid size={12}>
        {fields.map(field => renderField(field))}
      </Grid>

      <Grid container size={12} display="flex" sx={{ marginTop: "15px" }}>
        <Grid size={6} display="flex" justifyContent="flex-start">
          <Button variant="contained" style={{ backgroundColor: "red" }} onClick={() => { console.log("close") }}>
            Cancel
          </Button>
        </Grid>
        <Grid size={6} display="flex" justifyContent="flex-end">
          <Button variant="contained" style={{ backgroundColor: "#0b2343" }} onClick={handleSubmit}>
            Save
          </Button>
        </Grid>
      </Grid>


    </Grid>
  );
};

export default DynamicForm;
