import React from 'react';
import { TaskPanoModel } from '../../models/Task/Pano';
import TaskManagementTable from '../../components/TaskManagement/TaskManagementTable';

const TaskPano: React.FC = () => {
  // Update the columns to match TaskPanoModel
  const columns = [
    { label: 'Task', accessor: 'task' as keyof TaskPanoModel },
    { label: 'Responsible', accessor: 'responsible' as keyof TaskPanoModel },
    { label: 'Status', accessor: 'status' as keyof TaskPanoModel },
    { label: 'Target Date', accessor: 'targetDate' as keyof TaskPanoModel },
    { label: 'Priority', accessor: 'priority' as keyof TaskPanoModel },
    { label: 'Answer', accessor: 'answer' as keyof TaskPanoModel },
    { label: 'Budget', accessor: 'budget' as keyof TaskPanoModel },
    { label: 'Document', accessor: 'document' as keyof TaskPanoModel },
    { label: 'Timeline', accessor: 'timeline' as keyof TaskPanoModel },
    { label: 'Last Update Date', accessor: 'lastUpdateDate' as keyof TaskPanoModel },
    { label: 'Ref Num', accessor: 'refNum' as keyof TaskPanoModel },
    { label: 'Title/Article/Paragraph', accessor: 'titleArticleParagraph' as keyof TaskPanoModel },
    { label: 'Request Date', accessor: 'requestDate' as keyof TaskPanoModel },
  ];

  return (
    <TaskManagementTable
    />
  );
};

export default TaskPano;