import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Paper } from '@mui/material';
import { UserService, RoleService, PermissionService } from '../services/api';

const Dashboard: React.FC = () => {
  const [userCount, setUserCount] = useState(0);
  const [roleCount, setRoleCount] = useState(0);
  const [permissionCount, setPermissionCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const users = await UserService.getAll();
        const roles = await RoleService.getAll();
        const permissions = await PermissionService.getAll();

        setUserCount(users.length);
        setRoleCount(roles.length);
        setPermissionCount(permissions.length);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            <Typography variant="h6">Users</Typography>
            <Typography variant="h4">{userCount}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            <Typography variant="h6">Roles</Typography>
            <Typography variant="h4">{roleCount}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            <Typography variant="h6">Permissions</Typography>
            <Typography variant="h4">{permissionCount}</Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
