import React, { useState } from "react";
import Grid from '@mui/material/Grid2';
import { TextField } from "@mui/material";

interface TaskCreateComponentProps {
    onAddTask: (taskName: string) => void;
}

const TaskCreateComponent: React.FC<TaskCreateComponentProps> = ({ onAddTask }) => {

    const [newTaskName, setNewTaskName] = useState("");

    const createTask = () => {
        if (newTaskName.trim()) {
            onAddTask(newTaskName);
            setNewTaskName("");
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter" && newTaskName !== "") {
            createTask();
        }
    };

    return (
        <Grid columns={12} sx={{ margin: "5px" }}>
            <Grid size={3} sx={{ margin: "10px" }} display="flex" flexDirection="column">
                <TextField
                    value={newTaskName}
                    onChange={(e) => { setNewTaskName(e.target.value); }}
                    onBlur={() => {
                        if (newTaskName !== "") {
                            createTask();
                        }
                    }}
                    onKeyDown={handleKeyDown}
                    label="Enter New Task"
                    fullWidth
                />
                {newTaskName !== "" ? (
                    <p style={{ marginTop: "10px", fontSize: 13 }}>Press Enter or click on an empty space to save the task.</p>
                ) : null}
            </Grid>
        </Grid>
    )
};

export default TaskCreateComponent;
