import React from 'react';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid2';


interface EditModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}

const EditModal: React.FC<EditModalProps> = ({ open, onClose, title, children }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
        <Grid container size={12}>
          <Grid size={12} display="flex" justifyContent="space-between" alignItems="center">
            <Grid size={6}>
              <Typography variant="h5" sx={{ color: "#0b2343" }} component="h2">
                {title}
              </Typography>
            </Grid>
            <Grid size={6} display="flex" justifyContent="flex-end">
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          {children}
        </Grid>
      </Box>
    </Modal>
  );
};

export default EditModal;
