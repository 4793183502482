import React from 'react';
import { Role } from '../../models/Accounts/Role';
import RoleForm from '../../components/AccountsManagement/RoleForm';
import { RoleService } from '../../services/api';
import ManagementTable from '../../components/Common/ManagementTable';

const RoleManagement: React.FC = () => {
  const columns = [
    { label: 'Role Name', accessor: 'name' as keyof Role },
    { label: 'Permissions', accessor: 'permissions' as keyof Role },
  ];

  return (
    <ManagementTable
      title="Role"
      service={RoleService}
      columns={columns}
      FormComponent={RoleForm}
    />
  );
};

export default RoleManagement;
