import React from 'react';
import TaskProjectManagementTable from '../../components/TaskManagement/TaskProjectManagementTable';
import { Project } from '../../models/Task/Pano';

interface TaskProjectTableProps {
  onProjectListChange: (allProject: Project[]) => void;
}


const TaskProject: React.FC<TaskProjectTableProps> = ({onProjectListChange}) => {


  const handleProjectList = (allProject: Project[]) =>{
    onProjectListChange(allProject);
  }

  return (
    <TaskProjectManagementTable onProjectListChange={(allProjectList)=>{handleProjectList(allProjectList)}} />
  );
};

export default TaskProject;
