import React, { useState, useEffect } from 'react';
import { Select, MenuItem } from '@mui/material';

// Define the interface for props
interface TaskPriorityFieldProps {
    item: string; // Prop to receive the task name
    onPriorityChange: (newPriority: string) => void;
}

const TaskPriorityField: React.FC<TaskPriorityFieldProps> = ({ item, onPriorityChange }) => {
    const [taskPriority, setTaskpriority] = useState<string>('');

    useEffect(() => {
        setTaskpriority(item || '');
    }, [item]);


    const getColor = (value: string): { backgroundColor: string; color: string } | undefined => {
        if (value === "Critical") {
            return { backgroundColor: "#3b198e", color: "white" };
        } else if (value === "High") {
            return { backgroundColor: "#3a83bb", color: "white" };
        } else if (value === "Medium") {
            return {backgroundColor: "#5558d5", color: "white"};
        } else if (value === "Low") {
            return {backgroundColor: "#6799f5", color: "white"};
        }
        return undefined;  
    };
    
    return (

        <Select
            fullWidth
            value={taskPriority}
            label="Task Priority"
            onChange={(e) => {
                setTaskpriority(e?.target.value);
                onPriorityChange(e?.target.value);
            }}
            style={getColor(taskPriority)}

        >
            <MenuItem value="Critical" style={getColor("Critical")}>Critical</MenuItem>
            <MenuItem value="High" style={getColor("High")}>High</MenuItem>
            <MenuItem value="Medium" style={getColor("Medium")}>Medium</MenuItem>
            <MenuItem value="Low" style={getColor("Low")}>Low</MenuItem>

        </Select>
    );
};

export default TaskPriorityField;