import React, { useState, useEffect } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Card, CardContent, IconButton } from '@mui/material';
import { useNotification } from '../Common/Notifications';
import EditModal from '../Common/EditModal';
import Grid from '@mui/material/Grid2';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

interface ManagementTableProps<T> {
  title: string;
  service: {
    getAll: () => Promise<T[]>;
    create: (data: T) => Promise<T>;
    update: (id: string, data: T) => Promise<T>;
    delete: (id: string) => Promise<void>;
  };
  columns: Array<{ label: string; accessor: keyof T | string }>;
  FormComponent: React.FC<{ item?: T | null; onSave: (item: T) => void }>;
}

const ManagementTable = <T extends { _id: string }>({ title, service, columns, FormComponent }: ManagementTableProps<T>) => {
  const [items, setItems] = useState<T[]>([]);
  const [openForm, setOpenForm] = useState(false);
  const [editItem, setEditItem] = useState<T | null>(null);
  const { addNotification } = useNotification();

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const data = await service.getAll();
        if (Array.isArray(data)) {
          setItems(data);
        } else {
          console.error('API response is not an array:', data);
        }
      } catch (error) {
        console.error('Failed to fetch items:', error);
      }
    };
    fetchItems();
  }, [service]);

  const handleSave = async (item: T) => {
    if (editItem) {
      // Update existing item
      await service.update(editItem._id, item);
      setItems(items.map(i => (i._id === editItem._id ? item : i)));
      addNotification({ message: `${title} updated successfully`, severity: 'success' });
    } else {
      // Add new item
      const newItem = await service.create(item);
      setItems([...items, newItem]);
      addNotification({ message: `${title} added successfully`, severity: 'success' });
    }
    setOpenForm(false);
    setEditItem(null);
  };

  const handleEdit = (item: T) => {
    setEditItem(item);
    setOpenForm(true);
  };

  const handleDelete = async (id: string) => {
    await service.delete(id);
    setItems(items.filter(i => i._id !== id));
    addNotification({ message: `${title} deleted successfully`, severity: 'success' });
  };

  const renderCellValue = (item: T, accessor: keyof T | string) => {
    if (typeof accessor === 'string') {
      const value = accessor.split('.').reduce<any>((acc, key) => acc && acc[key], item);
      return typeof value === 'object' ? JSON.stringify(value) : value;
    }
    return item[accessor];
  };

  return (
    <Card square sx={{ margin: "5px" }}>
      <CardContent>
        <Grid columns={12} sx={{ margin: "5px" }} >
          <Grid size={12}>
            <Typography variant="h4" gutterBottom sx={{ color: "#0b2343" }}>
              {title} Management
            </Typography>
          </Grid>
        </Grid>

        <Grid container size={12} justifyContent="flex-end" sx={{ marginBottom: "2%" }}>
          <Button
            variant="contained"
            style={{ backgroundColor: "#0b2343" }}
            onClick={() => {
              setEditItem(null);
              setOpenForm(true);
            }}
          >
            Add {title}
          </Button>
        </Grid>
        <Grid size={12}>
          <TableContainer component={Paper}>
            <Table sx={{ border: "10px solid whitesmoke" }}>
              <TableHead sx={{ backgroundColor: "whitesmoke" }}>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell sx={{ fontWeight: "bold", fontSize: "15px" }} key={index}>{column.label}</TableCell>
                  ))}
                  <TableCell sx={{ fontWeight: "bold",fontSize: "15px" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
                {items.map((item, index) => (
                  <TableRow key={index}>
                    {columns.map((column, colIndex) => (
                      <TableCell key={colIndex}>{renderCellValue(item, column.accessor)}</TableCell>
                    ))}
                    <TableCell>
                        <IconButton onClick={() => handleEdit(item)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDelete(item._id)}>
                          <DeleteIcon style={{color:"red"}} />
                        </IconButton>
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <EditModal open={openForm} onClose={() => setOpenForm(false)} title={editItem ? `Edit ${title}` : `Add ${title}`}>
          <FormComponent item={editItem} onSave={handleSave} />
        </EditModal>
      </CardContent>
    </Card>
  );
};

export default ManagementTable;
