import React from 'react';
import { Role } from '../../models/Accounts/Role';
import { Permission } from '../../models/Accounts/Permission';
import DynamicForm from '../Common/DynamicForm';
import withEditMode from '../Common/withEditMode';

interface RoleFormProps {
  item?: Role | null;
  onSave: (role: Role) => void;
}

const RoleForm: React.FC<RoleFormProps> = ({ item, onSave }) => {
  const permissionData: Permission = {
    name: "",
    description: "",
    permission: []
  }

  const initialValues: Role = item || { name: '', permission: permissionData };
  const fields = [
    { name: 'name' as keyof Role, label: 'Role Name' },
    { name: 'permissions' as keyof Role, label: 'Permissions', type: 'checkbox', options: ['create', 'read', 'update', 'delete'] },
  ];

  return <DynamicForm initialValues={initialValues} fields={fields} onSave={onSave} />;
};

export default withEditMode(RoleForm);
