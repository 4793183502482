import React from 'react';
import { ThemeConfigModel } from '../../models/DataIntegration/ThemeConfig';
import ThemeForm from '../../components/DataIntegration/ThemeConfigForm';
import { ConfigThemeService } from '../../services/api';
import ManagementTable from '../../components/Common/ManagementTable';

const ThemeConfig: React.FC = () => {
  const columns = [
    { label: 'ThemeConfigModel Name', accessor: 'themeName' as keyof ThemeConfigModel },
    { label: 'Creation Date', accessor: 'creationDate' as keyof ThemeConfigModel },
    { label: 'Version', accessor: 'version' as keyof ThemeConfigModel },
    { label: 'ThemeConfigModel Fields', accessor: 'themeFields' as keyof ThemeConfigModel },
  ];

  return (
    <ManagementTable
      title="Theme Config"
      service={ConfigThemeService}
      columns={columns}
      FormComponent={ThemeForm}
    />
  );
};

export default ThemeConfig;
