import React, { useState } from "react";
import { CardContent, TextField, Button, IconButton, InputAdornment } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { Visibility, VisibilityOff } from '@mui/icons-material';



const ChangePassword: React.FC = () => {

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordAgain, setNewPasswordAgain] = useState("");

    const [showNewPassAgain, setShowNewPassAgain] = useState(false);
    const [showCurrentPass, setShowCurrentPass] = useState(false);
    const [showNewPass, setNewPass] = useState(false);


    const handleClickShowPassword = (value: string) => {

        if (value === "current") {
            setShowCurrentPass(!showCurrentPass)
        } else if (value === "new") {
            setNewPass(!showNewPass);
        } else if (value === "again") {
            setShowNewPassAgain(!showNewPassAgain);
        }
    };

    return (
        <CardContent>
        <Grid container rowSpacing={3}>
            <Grid size={12} sx={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <TextField
                    onChange={(e) => { setCurrentPassword(e.target.value) }}
                    value={currentPassword}
                    label="Current Password"
                    type={showCurrentPass ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => handleClickShowPassword("current")}
                                    edge="end"
                                    aria-label="toggle password visibility"
                                >
                                    {showNewPassAgain ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid size={12} sx={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <TextField
                    onChange={(e) => { setNewPassword(e.target.value) }}
                    value={newPassword}
                    label="New Password Password"
                    type={showNewPass ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => handleClickShowPassword("new")}
                                    edge="end"
                                    aria-label="toggle password visibility"
                                >
                                    {showNewPassAgain ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}


                />


            </Grid>
            <Grid size={12} sx={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <TextField
                    onChange={(e) => setNewPasswordAgain(e.target.value)}
                    value={newPasswordAgain}
                    label="New Password Again"
                    type={showNewPassAgain ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => handleClickShowPassword("again")}
                                    edge="end"
                                    aria-label="toggle password visibility"
                                >
                                    {showNewPassAgain ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

            </Grid>
            <Grid size={12} sx={{display:"flex",alignItems:"center",justifyContent:"center"}}>

                <Button
                    variant="outlined"
                    size="medium"
                    sx={{
                        backgroundColor: "#102240",
                        color: "white",
                        "&.Mui-disabled": {
                            color: "white",
                            opacity: 0.5,
                        },
                    }}
                    component="span"
                    disabled={currentPassword === "" || newPassword === "" || newPasswordAgain === ""}
                >
                    Change Your Password
                </Button>
            </Grid>

        </Grid>
    </CardContent>
    )
}


export default ChangePassword;
