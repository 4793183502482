import React, { useState } from 'react';
import { TextField, Button, Box, Typography, FormControlLabel, Checkbox, Link, IconButton, InputAdornment } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const { login } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async () => {
    await login(email, password);
    navigate('/dashboard'); // Redirect to dashboard after login
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: "whitesmoke",
        padding: 2,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          padding: 4,
          borderRadius: 2,
          boxShadow: 3,
          width: '100%',
          maxWidth: 400,
        }}
      >
        <Box
          sx={{
            textAlign: 'center',
            marginBottom: 3,
          }}
        >
          <img src="/assets/images/logo-light.png" alt="Planitsecure-Logo" style={{ height: 40, marginBottom: 8 }} />
          <Typography variant="h5" fontWeight="bold" color="#0b2343">
            Hi, Welcome Back
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Enter your credentials to continue
          </Typography>
        </Box>
        <TextField
          label="Email Address"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(e) => { setEmail(e.target.value) }}

        />
        <TextField
          label="Password"
          type={showPassword ? 'text' : 'password'}
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  edge="end"
                  aria-label="toggle password visibility"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginY: 2,
          }}
        >
          <FormControlLabel
            control={<Checkbox color="primary" />}
            label="Keep me logged in"
          />
          <Link href="#" color="primary" variant="body2">
            Forgot Password?
          </Link>
        </Box>

        <div style={{ justifyContent: "center", textAlign: "center", display: "flex" }}>
          <Button
            variant="contained"
            size="medium"
            onClick={handleLogin}
            disabled={email === "" || password === ""}
            sx={{
              backgroundColor: "#102240",
              width: "70%",
            }}
          >
            Sign In
          </Button>
        </div>

      </Box>
    </Box>
  );
};

export default Login;
