import { Typography, CardContent, Avatar, IconButton, TextField, Box } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import { HexColorPicker } from "react-colorful";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { useAuth } from "../../context/AuthContext";

const UserInformation: React.FC = () => {

    const { user, role } = useAuth();
    const [avatarText, setAvatarText] = useState(`${user?.firstname?.[0].toLocaleUpperCase() ?? ''}${user?.lastname?.[0].toUpperCase() ?? ''}`);
    const [avatarColor, setAvatarColor] = useState("green");
    const [editColor, setEditColor] = useState(false);


    const handleSaveAvatarColor = (newColor: string) => {
        setAvatarColor(newColor);
    };


    return (
        <CardContent>
            <Grid container size={12} spacing={1} sx={{ alignItems: 'center' }}>
                <Grid container size={12} columnSpacing={1} direction="row" alignItems="center">
                    <Grid size={2}>
                        <Typography variant="body1" sx={{ color: "#0b2343", fontWeight: "bold", fontSize: "18px" }}>
                            Name
                        </Typography>
                    </Grid>
                    <Grid size={3}>{user?.firstname}</Grid>
                </Grid>
                <Grid container size={12} columnSpacing={1} direction="row" alignItems="center">
                    <Grid size={2}>
                        <Typography variant="body1" sx={{ color: "#0b2343", fontWeight: "bold", fontSize: "18px" }}>
                            Surname
                        </Typography>
                    </Grid>
                    <Grid size={3}>{user?.lastname}</Grid>
                </Grid>
                <Grid container size={12} columnSpacing={1} direction="row" alignItems="center">
                    <Grid size={2}>
                        <Typography variant="body1" sx={{ color: "#0b2343", fontWeight: "bold", fontSize: "18px" }}>
                            Email
                        </Typography>
                    </Grid>
                    <Grid size={3}>{user?.email}</Grid>
                </Grid>
                <Grid container size={12} columnSpacing={1} direction="row" alignItems="center">
                    <Grid size={2}>
                        <Typography variant="body1" sx={{ color: "#0b2343", fontWeight: "bold", fontSize: "18px" }}>
                            Role
                        </Typography>
                    </Grid>
                    <Grid size={3}>{role}</Grid>
                </Grid>
                <Grid container size={12} columnSpacing={1} direction="row" alignItems="center">
                    <Grid size={12} display="flex" alignItems="center">
                        <Avatar sx={{ backgroundColor: avatarColor }}>
                            {avatarText}
                        </Avatar>
                        {!editColor ? (
                            <IconButton sx={{ marginLeft: 1 }} onClick={() => setEditColor(!editColor)}>
                                <EditIcon />
                            </IconButton>
                        ) : null}
                    </Grid>

                    <Grid size={12} display="flex" alignItems="center" sx={{ marginLeft: { xs: 0, sm: 2 }, marginTop: { xs: 2, sm: 0 } }}>
                        {editColor ? (
                            <Grid container alignItems="center">
                                <Grid size={8}>
                                    <HexColorPicker
                                        color={avatarColor}
                                        onChange={(newColor) => { handleSaveAvatarColor(newColor); }}
                                    />
                                    <Box mt={1}>
                                        <TextField
                                            value={avatarText}
                                            onChange={(e) => {
                                                if (e.target.value.length <= 3) {
                                                    setAvatarText(e.target.value.toLocaleUpperCase());
                                                }
                                            }}
                                            size="small"
                                        />
                                    </Box>
                                </Grid>

                                <Grid size={4} display="flex" justifyContent="flex-end">
                                    <IconButton sx={{ marginLeft: "3px" }} onClick={() => {
                                        setEditColor(!editColor);
                                    }}>
                                        <SaveIcon />
                                    </IconButton>
                                    <IconButton onClick={() => setEditColor(!editColor)}>
                                        <CancelIcon sx={{ color: "red" }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ) : null}
                    </Grid>
                </Grid>
            </Grid>
        </CardContent>
    )
}


export default UserInformation;
