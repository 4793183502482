import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid2';
import { SwipeableDrawer, Typography, TextField, IconButton, Button, Box } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import { Project } from "../../../models/Task/Pano";
// import UserSelect from "../../Common/UserSelect";
import TaskUserSelect from "./TaskUserSelect";
import { TaskManagementService } from "../../../services/api";

interface TaskProjectCreateProps {
    open: boolean;
    onClose: () => void;
    project: Project | null;
    type: string;
}

const TaskProjectCreate: React.FC<TaskProjectCreateProps> = ({ open, onClose, project, type }) => {

    const [allProjectList, setAllProjectList] = useState<Project[] | null>([]);
    const [currentProject, setCurrentProject] = useState<Project | null>();

    //Project field value
    const [name, setName] = useState<string>("");
    const [code, setCode] = useState<string>("");
    const [coordinator, setCoordinator] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [independentAuditor, setIndependentAuditor] = useState<string>("");

    // Validate Value
    const [existCode, setExistCode] = useState<boolean>(false);
    const [existName, setExistName] = useState<boolean>(false);

    useEffect(() => {
        getAllProject();
        setCurrentProject(project || null);
        setName(project?.name || "");
        setCode(project?.code || "");
        setCoordinator(project?.coordinatorName || "");
        setDescription(project?.description || "");
        setIndependentAuditor(project?.independentAuditor || "");
    }, [project]);

    const createProject = async () => {
        if (type === "Create") {
            const project: Project = {
                code: code,
                name: name,
                coordinatorName: coordinator,
                creationDate: new Date(),
                description: description,
                independentAuditor: independentAuditor,
            };
            const response = await TaskManagementService.create(project, "project");
            console.log("my project create response -> ", response);
            onClose();
            clearFields();
        }else{
            const updateProject = {...currentProject};

            updateProject.name = name;
            updateProject.code = code;
            updateProject.coordinatorName = coordinator;
            updateProject.description = description;
            updateProject.independentAuditor = independentAuditor;
            const response = await TaskManagementService.update(updateProject.code,updateProject,"project");
            console.log("project update response -> ", response);
            onClose();
            clearFields();
        }
    };

    const getAllProject = async () => {
        const response = await TaskManagementService.getAll("project");
        setAllProjectList(response);
    };

    const findProjectCode = (code: string) => {
        let found = false;
        allProjectList?.forEach((project) => {
            if (project?.code === code) {
                found = true;
                return; 
            }
        });
        return found;
    };

    const findProjectName = (name: string) => {
        let found = false;
        allProjectList?.forEach((project) => {
            if (project?.name === name) {
                found = true;
                return;
            }
        });
        return found;
    };


    const clearFields = () => {
        setName("");
        setCode("");
        setCoordinator("");
        setDescription("");
        setIndependentAuditor("");
        setExistCode(false);
        setExistName(false);
    };

    const handleFieldChange = (fieldName: string, newValue: string) => {
        if (fieldName === "name") {
            setName(newValue);
            setExistName(findProjectName(newValue));
        } else if (fieldName === "code") {
            setExistCode(findProjectCode(newValue));
            setCode(newValue);
        } else if (fieldName === "coordinator") {
            setCoordinator(newValue);
        } else if (fieldName === "description") {
            setDescription(newValue);
        } else if (fieldName === "independentAuditor") {
            setIndependentAuditor(newValue);
        }
    };

    const projectCodeHelperText = () => {
        if (existCode) {
            return "This project code is being used. Write another one.";
        }
        return "";
    };
    
    const projectNameHelperText = () => {
        if (existName) {
            return "This project name is being used. Write another one.";
        }
        return "";
    };


    return (
        <SwipeableDrawer
            anchor="right"
            open={open}
            onClose={() => {
                clearFields();
                onClose();
            }}
            onOpen={() => { }}
            PaperProps={{ style: { width: "60vh", padding: 20, display: "flex", flexDirection: "column", justifyContent: "space-between" } }}
        >
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 15 }}>
                    <Typography variant="h5" sx={{ color: "#0b2343" }}>
                        {type} Project
                    </Typography>
                    <IconButton onClick={() => {
                        clearFields();
                        onClose();
                    }}>
                        <CloseIcon />
                    </IconButton>
                </div>

                <div>
                    <Typography variant="body1" sx={{ color: "#0b2343" }}>
                        Enter your project details
                    </Typography>
                </div>

                <Grid container spacing={5} justifyContent="center" sx={{ marginTop: "10%" }}>
                    <Grid size={12}>
                        <TextField
                            label="Project Name"
                            value={name || ""}
                            error={existName}
                            helperText={projectNameHelperText()}
                            fullWidth
                            onChange={(e) => { handleFieldChange("name", e.target.value) }}
                            disabled={type!=="Create"}
                        />
                    </Grid>

                    <Grid size={12}>
                        <TextField
                            label="Project Code"
                            value={code || ""}
                            fullWidth
                            helperText={projectCodeHelperText()}
                            error={existCode}
                            disabled={type!=="Create"}
                            onChange={(e) => {
                                if (e.target.value.length <= 3) {
                                    handleFieldChange("code", e.target.value);
                                }
                            }}
                        />
                    </Grid>

                    <Grid size={6}>
                        <Typography variant="body1" gutterBottom>
                            Coordinator
                        </Typography>
                        <TaskUserSelect
                            user={coordinator || ""}
                            onTaskUserChange={(newUser) => { handleFieldChange("coordinator", newUser) }}
                            permission={false}
                            show={true}
                        />
                    </Grid>

                    <Grid size={6}>
                        <Typography variant="body1" gutterBottom>
                            Independent Auditor
                        </Typography>
                        <TaskUserSelect
                            user={independentAuditor || ""}
                            onTaskUserChange={(newUser) => { handleFieldChange("independentAuditor", newUser) }}
                            permission={false}
                            show={true}
                        />
                    </Grid>

                    <Grid size={12}>
                        <TextField
                            label="Project Description"
                            value={description || ""}
                            fullWidth
                            multiline
                            rows={3}
                            onChange={(e) => { handleFieldChange("description", e.target.value) }}
                        />
                    </Grid>
                </Grid>
            </div>



            <Box mt={3} display="flex" justifyContent="space-between">
                <Button
                    variant="outlined"
                    size="medium"
                    onClick={() => {
                        clearFields();
                        onClose();
                    }}
                    style={{ backgroundColor: "#c10101", color: "white" }}
                >
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    size="medium"
                    sx={{
                        backgroundColor: "#0b2343",
                        "&.Mui-disabled": {
                            backgroundColor: "grey",
                            color: "white",
                        },
                    }}
                    onClick={() => { createProject() }}
                    disabled={existCode || existName || name === "" || code === "" || coordinator === ""}
                >
                    Save
                </Button>
            </Box>

        </SwipeableDrawer>
    );
}

export default TaskProjectCreate;
