import React, { useState, useEffect } from 'react';
import { Typography, TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';
import TaskDatePicker from './TaskDatePicker';
import TaskUserSelect from './TaskUserSelect';

// Define the interface for props
interface TaskUserDetailProps {
  selectedTicketOwner: string;
  selectedTicketOwnerManager: string;
  selectedInternalAuditor: string;
  selectedIndependentAuditor: string;
  targetDate: Date | null;
  requestDate: Date | null;
  creationDate: Date | null;
  lastUpdate: Date | null;
  onTicketOwnerChange: (newTicketOwner: string) => void;
  onTicketOwnerManagerChange: (newTicketOwner: string) => void;
  onInternalAuditorChange: (newInternalAuditor: string) => void;
  onIndependentAuditorChange: (newIndependentAuditor: string) => void;
  onTargetDateChange: (newTargetDate: Date | null) => void;
  disabled: boolean;
}

const TaskUserDetail: React.FC<TaskUserDetailProps> = ({
  selectedTicketOwner,
  selectedTicketOwnerManager,
  selectedInternalAuditor,
  selectedIndependentAuditor,
  targetDate,
  requestDate,
  creationDate,
  lastUpdate,
  disabled,
  onTicketOwnerChange,
  onTicketOwnerManagerChange,
  onInternalAuditorChange,
  onIndependentAuditorChange,
  onTargetDateChange,
}) => {
  const [ticketOwner, setTicketOwner] = useState<string>('');
  const [ticketOwnerManager, setTicketOwnerManager] = useState<string>('');
  const [internalAuditor, setInternalAuditor] = useState<string>('');
  const [independentAuditor, setIndependentAuditor] = useState<string>('');
  const [targDate, setTargDate] = useState<Date | null>();
  const [reqtDate, setRequestDate] = useState<Date | null>();
  const [creaDate, setCreationDate] = useState<Date | null>();
  const [laUpdate, setLastUpdate] = useState<Date | null>();

  useEffect(() => {
    setTicketOwner(selectedTicketOwner || '');
    setTicketOwnerManager(selectedTicketOwnerManager || '');
    setInternalAuditor(selectedInternalAuditor || '');
    setIndependentAuditor(selectedIndependentAuditor || '');
    setTargDate(targetDate || null);
    setRequestDate(requestDate || null);
    setCreationDate(creationDate || null);
    setLastUpdate(lastUpdate || null);
  }, [
    selectedTicketOwner,
    selectedTicketOwnerManager,
    selectedInternalAuditor,
    selectedIndependentAuditor,
    targetDate,
    requestDate,
    creationDate,
    lastUpdate,
  ]);

  const handleTicketOwnerChange = (newTicketOwner: string) => {
    setTicketOwner(newTicketOwner);
    onTicketOwnerChange(newTicketOwner);
  };

  const handleTicketOwnerManagerChange = (newTicketOwnerManager: string) => {
    setTicketOwnerManager(newTicketOwnerManager);
    onTicketOwnerManagerChange(newTicketOwnerManager);
  };

  const handleInternalAuditorChange = (newInternalAuditor: string) => {
    setInternalAuditor(newInternalAuditor);
    onInternalAuditorChange(newInternalAuditor);
  };

  const handleIndependentAuditorChange = (newIndependentAuditor: string) => {
    setIndependentAuditor(newIndependentAuditor);
    onIndependentAuditorChange(newIndependentAuditor);
  };

  const handleTargetDate = (newTargetDate: Date | null) => {
    setTargDate(newTargetDate);
    onTargetDateChange(newTargetDate);
  };

  return (
    <Grid container columns={12}>
      <Grid container columnSpacing={2} size={12}>
        <Grid container size={6} alignItems="center">
          <Typography variant="body1">Responsible</Typography>
        </Grid>

        <Grid size={6}>
          <TaskUserSelect
            user={ticketOwner}
            onTaskUserChange={(newUser) => {
              handleTicketOwnerChange(newUser);
            }}
            permission={false}
            show={true}
          />
        </Grid>
      </Grid>

      {disabled ? null : (
        <>
          <Grid container columnSpacing={2} size={12}>
            <Grid container size={6} alignItems="center">
              <Typography variant="body1">Approver</Typography>
            </Grid>

            <Grid size={6}>
              <TaskUserSelect
                user={ticketOwnerManager}
                onTaskUserChange={(newUser) => {
                  handleTicketOwnerManagerChange(newUser);
                }}
                permission={false}
                show={true}
              />
            </Grid>
          </Grid>

          <Grid container columnSpacing={2} size={12}>
            <Grid container size={6} alignItems="center">
              <Typography variant="body1">Internal Auditor</Typography>
            </Grid>

            <Grid size={6}>
              <TaskUserSelect
                user={internalAuditor}
                onTaskUserChange={(newUser) => {
                  handleInternalAuditorChange(newUser);
                }}
                permission={false}
                show={true}
              />
            </Grid>
          </Grid>

          <Grid container columnSpacing={2} size={12}>
            <Grid container size={6} alignItems="center">
              <Typography variant="body1">Independent Auditor</Typography>
            </Grid>

            <Grid size={6}>
              <TaskUserSelect
                user={independentAuditor}
                onTaskUserChange={(newUser) => {
                  handleIndependentAuditorChange(newUser);
                }}
                permission={false}
                show={true}
              />
            </Grid>
          </Grid>
        </>
      )}

      <Grid container columnSpacing={2} size={12} sx={{ marginTop: '10px' }}>
        <Grid container size={6} alignItems="center">
          <Typography variant="body1">Request Date</Typography>
        </Grid>

        <Grid size={6}>
          <Typography variant="body1">
            <TextField
              type="date"
              disabled={true}
              value={reqtDate ? new Date(reqtDate).toISOString().slice(0, 10) : ""}
              variant="standard"
              slotProps={{
                input: {
                  disableUnderline: true,
                },
              }}
            />
          </Typography>
        </Grid>
      </Grid>

      <Grid container columnSpacing={2} size={12}>
        <Grid container size={6} alignItems="center">
          <Typography variant="body1">Creation Date</Typography>
        </Grid>

        <Grid size={6}>
          <Typography variant="body1">
            <TextField
              type="date"
              disabled={true}
              value={creaDate ? new Date(creaDate).toISOString().slice(0, 10) : ""}
              variant="standard"
              slotProps={{
                input: {
                  disableUnderline: true,
                },
              }}
            />
          </Typography>
        </Grid>
      </Grid>

      <Grid container columnSpacing={2} size={12}>
        <Grid container size={6} alignItems="center">
          <Typography variant="body1">Target Date</Typography>
        </Grid>

        <Grid size={6}>
          <TaskDatePicker
            date={targDate || null}
            onTaskDateChange={(newDate) => {
              handleTargetDate(newDate);
            }}
            permission={false}
            minDate={creaDate || new Date()}
          />
        </Grid>
      </Grid>

      <Grid container columnSpacing={2} size={12}>
        <Grid container size={6} alignItems="center">
          <Typography variant="body1">Last Update Date</Typography>
        </Grid>

        <Grid size={6}>
          <Typography variant="body1">
            <TextField
              type="date"
              disabled={true}
              value={laUpdate ? new Date(laUpdate).toISOString().slice(0, 10) : ""}
              variant="standard"
              slotProps={{
                input: {
                  disableUnderline: true
                },
              }}
            />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TaskUserDetail;
