import React, { useEffect, useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import LanguageIcon from '@mui/icons-material/Language';
import PersonIcon from '@mui/icons-material/Person';


const Navbar: React.FC = () => {
    const [minHeight, setMinHeight] = useState<string>('10%');
    const { logout } = useAuth();
    const navigate = useNavigate();


    const updateMinHeight = () => {
        const width = window.innerWidth;

        if (width < 1200) {
            setMinHeight('9%');
        } else if (width >= 1200 && width < 1800) {
            setMinHeight('8%');
        } else {
            setMinHeight('6.5%');
        }
    };

    const redirectLogout = () => {
        logout();
        navigate('/login');
    };

    const redirectProfile = () => {
        navigate("/profile");
    };


    useEffect(() => {
        updateMinHeight();
        window.addEventListener('resize', updateMinHeight);
        return () => {
            window.removeEventListener('resize', updateMinHeight);
        };
    }, []);

    return (
        <Box
            sx={{
                backgroundColor: "whitesmoke",
                position: 'fixed',
                top: 0,
                width: '100%',
                zIndex: 1000,
                height: "auto",
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                padding: '0 16px',
                minHeight: minHeight,
            }}
        >
            <IconButton sx={{ color: '#0b2343' }}>
                <LanguageIcon />
            </IconButton>
            <IconButton onClick={redirectProfile} sx={{ color: '#0b2343' }}>
                <PersonIcon />
            </IconButton>
            <Tooltip title="Logout">
                <IconButton sx={{ color: '#0b2343' }} onClick={redirectLogout}>
                    <ExitToAppIcon />
                </IconButton>
            </Tooltip>
        </Box>
    );
}

export default Navbar;
